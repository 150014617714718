/*errorstyles*/



.content-landing { position: relative;
width:100%; height:100%; }

.center{
	text-align: center;
}

.promo-question{
	font-weight: bold;
}
.promo-text{

	font-size: 20px;
}
.promo-link{
	font-size: 20px;
	color: #0099ff !important;
	text-decoration:underline;
}

#promo{
	text-align: center;
}
#cl-wrapper{
	font-size:18px;
	height: 700px;
	display: table;
	width: 100%;
	text-align: center;
}
#cl-wrapper .cl-wrapper-content {
	vertical-align:middle;
	display: table-cell;
	font-size: 26px;
	color: #333333;
}
/*Start Landing page styles*/
.cdt-new-land {
	padding: 165px 0px;
	margin-top: 55px;
}
.cdthome-content {
	position: relative;
	width: 75%;
	margin: 0px auto;

}
.land-big-text {
	font-size: 28px;
	font-weight: bold;
}
.land-sub-text {
	font-size: 20px;
}
.land-small-text {
	font-size: 14px;
	color: #808080;
}
.have-text {
	font-size: 16px;
	color: #808080;
}
.cdt-content-sub {
	padding: 60px 0px;
}
.bg-land-fgrey {
	background-color: #f8f8f8;
}
.cdt-services {
	margin-bottom: 50px;
}
.sblock-icon > img {
	margin: 0 auto;
}
h2.sblock-title {
	margin: 0px;
	padding: 20px 0px;
	font-size: 20px;
	font-weight: bold;
	color: #333333;
	text-align: center;
}
p.sblock-text {
	color: #666;
	font-size: 16px;
}
.feature-block {
	margin-bottom: 40px;
}
.feature-content h2.sblock-title {
	text-align: left;
	font-size: 24px;
}
.feature-content p.sblock-text {
	font-size: 20px;
}
/*.f-leranmore {
	color: #0099ff !important;
	text-decoration: underline;
	font-size: 20px !important;
}*/
/*Start login/signup page styles*/
.login-wrapper {
	padding-top: 100px;
	text-align: center;
}
.login-box {
	max-width: 550px;
	margin: 0px auto;
	padding: 30px;
}
.login-box-shadow {
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);background-color: #f8f8f8;
}
.wantBtn {
	text-decoration: underline;
	color: #0099FF;
	padding: 0px;
}
.sub-form-text {
	color: #333;
	font-size: 14px;
}
.otp-form {
	width: auto !important;
	margin: 0px auto;
	font-size: 20px;
	color: #000000;
}
.pwdInput {
	width: auto;
	margin: 0 auto;
}
.btn-textlc {
	font-size: 16px !important;
}

/*Social Buttons*/
.google-btn-G {
	background-color: #ffffff !important;
	border: 1px solid #0277bd;
	color: #ffffff ;
	font-weight: bold;
	text-transform: none;
}
.google-btn-G:hover, .google-btn-G:focus {
	background-color: #ffffff !important;
	border: 1px solid #2b7de9 !important;
	color: #ffffff !important;
}
.google-btn {
	background-color: #0277bd !important;
	border: 1px solid #0277bd !important;
	color: #ffffff !important;
	font-weight: bold !important;
	font-size: 16px !important;
	text-transform: none !important;
}
.google-btn:hover, .google-btn:focus {
	background-color: #2b7de9 !important;
	border: 1px solid #2b7de9 !important;
	color: #ffffff !important;
}
.facebook-btn {
	background-color: #3b5998 !important;
    border: 1px solid #3b5998 !important;
    color: #ffffff !important;
    font-weight:bold !important;
    text-transform: none;
    font-size: 16px !important;

}
.facebook-btn:hover, .facebook-btn:focus {
	background-color: #4267b2 !important;
	border-color: #4267b2 !important;
	color: #ffffff !important;
}

.twitter-btn {
	background-color: rgb(29, 161, 242) !important;
	border: 1px solid rgb(29, 161, 242) !important;
	color: #ffffff;
	font-weight:bold!important;
	font-size: 16px !important;
	text-transform: none !important;
}
.twitter-btn:hover, .twitter-btn:focus {
	background-color: #005fd1 !important;
	border-color: #005fd1 !important;
	color: #ffffff !important;
}
.social-btns .twitter-btn, .social-btns .facebook-btn, .social-btns .google-btn, .social-btns .google-btn-G {
	font-size: 16px;
	margin-bottom: 5px;
}
.btn-group-margin {
	margin: 0px 32px;
}

@media only screen and (max-width: 480px) {
	.login-wrapper {
		padding-top: 15px !important;
		padding-bottom: 15px;
	}
	.fix-header-space {
		margin-top: 90px;
	}
}
@media (max-width:767px) {
	.cdt-new-land {
    padding: 40px 0px;
    margin-top: 0px;
	}
	.cdthome-content {
		width: 95%;
	}
	.btn-group-margin {
    margin: 0px 5px;
	}
}
#continue{
	font-size: 16px !important;
	    padding: 10px 16px !important;
}
#code{
	font-size: 	20px !important;
}
#logo{
	font-size: 24px !important;
	font-weight: bold;
	color:#333333 !important;
}
.logo-mn{
	color: #0099ff !important;
	font-size: 28px;
	font-weight: bold;
}
#logIn{
	font-weight: bold;

}
.help-link{
	text-align: start;
	color: #0099ff !important;
	text-decoration: underline;
	font-size: 20px !important;
}
.send-code-text{
	color: #808080 !important;
}

