#page404 #logIn{
	background-color: rgb(2, 119, 189);
	border-color: rgb(2, 119, 189);
	color: rgb(255, 255, 255);
	font-size: 16px;
}

#page404 #signUp{
	background-color: rgb(255,111,0);
	border-color: rgb(255,111,0);
	color: rgb(255,255,255);
	font-size: 16px;
	font-weight: 700 !important;
	margin-left: 10px;
}

#page404 #back{
	background-color: rgb(91, 99, 105);
	border-color: rgb(91, 99, 105);
	color: rgb(255,255,255);
	font-size: 16px;
	font-weight: 700;
	margin-left: 10px;
	margin-right: -5px;
}

#page404 .error-message{
	color: rgb(51, 51, 51);
	font-size: 28px;
	text-align: center;
}

#page404 #helpLine{
	font-size: 20px;
	text-align: center;
}

#page404 #home{
	color: rgb(0, 153, 255);
	cursor: pointer;
}

#page404 #support{
	color: rgb(0, 153, 255);
	cursor: pointer;
}

#page404 .landing-header {
	display: flex;
	flex-direction: row;
}

#page404 .landing-header .buttons{
	display: flex;
	margin-left: auto;
	padding-bottom: 5px;
	padding-top: 5px;
}

#page404 #text-center{
	align-items: center;
	display: flex;
	justify-content: center;
	text-align: center;
	height: 75vh;
	width: 100%;}

#page404 #logo{
	color: rgb(51,51,51);
	display: flex;
	font-size: 24px;
	font-weight: 700;
	text-decoration: none;
}

#page404 #logo .logo-mn{
	color: rgb(0, 153, 255) !important;
	font-size: 28px;
	font-weight: 700;
	margin-top: -3px;
}
