

@font-face {
	font-family: 'Arial';
	font-weight: normal;
	font-style: normal;
}
body {
	font-family: "Arial", sans-serif;
	color: #333333;
}



/*landinpage*/
.info-name{
	font-size:20px !important;
	font-weight: bold;
	text-align: center;
}
.info-text{
	color: #666666 !important;
	font-size:16px !important; 
	text-align:start;
}
.help-name{
	font-size:24px !important;
	font-weight:bold;
	text-align: start;
}
.help-text{
	color: #666666 !important;
	font-size:20px !important;
	text-align: start;
}

#logo{
	font-size: 24px !important;
	font-weight: bold;
	color:#333333 !important;
}
.logo-mn{
	color: #0099ff !important;
	font-size: 28px;
	font-weight:bold;
}

#logInLink{
	font-weight:400!important;
	font-size: 16px !important;
}

/*Header Style s*/
.landing-header {
	padding: 8px 15px;
	background-color: #f8f8f8;
	border-bottom: 1px solid #0099ff !important;
	box-shadow: 0px 4px 9px -2px #838080;

}

.lheader-fixed {
	position: fixed;
	width: 100%;
	top: 0px;
	z-index: 99;
	box-shadow: 0px 4px 9px -2px #838080;
}

.landing-header .lh-left {
	font-size: 24px !important;
	font-weight: bold;
	cursor: pointer;
	color: #333333 !important;
}

.lh-left span.cls-cdt {
	font-size: 26px;
}

.lr-links {
	padding: 0px;
	margin: 0px;
}

.lr-links li {
	list-style-type: none;
	display: inline-block;
}

.lr-links li > .button-link {
	font-size: 16px;
	font-weight: normal;
	padding: 6px 12px;
}

/*Common styles*/
.btn {
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	border-radius: 2px !important;
}

.btn:active, .btn:focus, .btn:hover {
	box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
	outline: 0;
}

.cls-primary {
	color: #0277bd !important;
}

.cls-success {
	color: #00c851 !important;
}

.cls-danger {
	color: #ff3547 !important;
}

.cls-warning {
	color: #fb3 !important;
}

/*.cls-cdt {
	color: #0099ff !important;
}*/
.cls-mn-blue {
	color: #0099ff !important;
}
.cls-white {
	color: #ffffff !important;
}
.cls-default {
	color: #333333; 
}
.cls-gray {
	color: #808080 !important;
}
.cls-blue {
	color: #0b4ba3 !important;
}
.cls-green {
	color: #008000 !important;
}
.cls-red {
	color: #ff0000 !important;
}
.cls-meroon {
	color: #800000 !important;
}
.mn-logo {
	color: #333333;
	white-space: nowrap;
}
.bg-white {
	background-color: #ffffff !important;
}
.btn-primary {
	background-color: #0277bd !important;
	color: #ffffff !important;
	border-color: #0277bd !important;
	padding: 3px 6px !important;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:active:focus {
	background-color: #0277bd !important;
	color: #ffffff !important;
	border-color: #0277bd;
}
.btn-success {
	background-color: #00c851 !important;
	color: #ffffff;
	border-color: #00c851;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success:active:focus {
	background-color: #1e7e34 !important;
	color: #ffffff;
	border-color: #1e7e34;
}
.btn-danger {
	background-color: #ff3547 !important;
	color: #ffffff;
	border-color: #ff3547;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger:active:focus {
	background-color: #ff4f5e !important;
	color: #ffffff;
	border-color: #ff4f5e;
}

.btn-warning {
	background-color: #ff6f00 !important;
	color: #ffffff;
	border-color: #ff6f00;	
	padding: 3px 6px !important;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning:active:focus {
	background-color: #ff6f00 !important;
	color: #ffffff;
	border-color: #ff6f00;
}
.btn-info {
	background-color: #33b5e5 !important;
	color: #ffffff;
	border-color: #33b5e5;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info:active:focus {
	background-color: #4abde8 !important;
	color: #ffffff;
	border-color: #4abde8;
}
.btn-default {
	background-color: #0277bd!important;
	color: #ffffff;
	border-color: #0277bd!important;
	color: #ffffff !important;
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default:active:focus  {
	background-color: #0099ff !important;
	color: #ffffff;
	border-color: #0099ff !important;
}
.btn-bold  {
	font-size: 16px !important;
	font-weight: bold;
}
.button-link {
	background-color: transparent !important;
	border: 1px solid transparent !important;
	cursor: pointer !important;
	padding-left: 0px !important;
	clear: both !important;
}
.button-link:hover, .button-link:focus {
	text-decoration: underline;
}
/*.under-line {
	text-decoration: underline;
}*/
.center{
	text-align:center !important;
}

.form-control:focus {
	border-color: #0277bd !important;
}
input {
	caret-color: red;
}
.input-error {
	border: 1px solid #ff3547;
}
@media (max-width:767px) {
	.lh-left span.visible-xs {
		display: inline-block !important;
	}
	.lr-links li > .button-link {
	padding: 6px !important;
	}
}

/*404 page logo css*/
#page404 #logo{
	color: rgb(51,51,51)!important;
	display: flex;
	font-size: 24px !important;
	font-weight: 700;
	align-items: center;
	text-decoration: none;
}

#page404 #logo .logo-mn{
	color: rgb(0, 153, 255) !important;
	font-size: 28px;
	font-weight: 700;
	margin-top: -3px;
}

#page404 .landing-header {
	border-bottom: 1px solid #0099ff !important;
	background-color: #f8f8f8;
	display: flex;
	flex-direction: row;
	padding: 8px 15px;
}

#page404 .landing-header .buttons{
	display: flex;
	margin-left: auto;
	padding-bottom: 5px;
	padding-top: 5px;
}

/*login page logo header styles*/
#logInPage #logo{
	color: rgb(51,51,51)!important;
	display: flex;
	font-size: 24px !important;
	font-weight: 700;
	align-items: center;
	margin-right: auto;
	text-decoration: none;
}

#logInPage #logo .logo-mn{
	color: rgb(0, 153, 255) !important;
	font-size: 28px;
	font-weight: 700;
	margin-top: -3px;	
}

#logInPage .landing-header{
	border-bottom: 1px solid #0099ff !important;
	background-color: #f8f8f8;
	display: flex;
	flex-direction: row;
	padding: 8px 15px;
}

#logInPage .landing-header .button{
	align-items: center;
	display: flex;
	margin-left: auto !important;
	padding-bottom: 5px !important;
	padding-top: 5px !important;
}

/*signup page logo header styles*/
#signUpPage #logo{
	color: rgb(51,51,51)!important;
	display: flex;
	font-size: 24px !important;
	font-weight: 700;
	align-items: center;
	margin-right: auto;
	text-decoration: none;	
}

#signUpPage #logo .logo-mn{
	color: rgb(0, 153, 255) !important;
	font-size: 28px;
	font-weight: 700;
	margin-top: -3px;	
}


#signUpPage .landing-header{
	border-bottom: 1px solid #0099ff !important;
	background-color: #f8f8f8;
	display: flex;
	flex-direction: row;
	padding: 8px 15px;
}

#signUpPage .landing-header .button{
	align-items: center;
	display: flex;
	margin-left: auto !important;
	padding-bottom: 5px !important;
	padding-top: 5px !important;
}






