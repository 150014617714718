body{
	font-size: 16px;
	font-family: sans-serif;
}
.nc_ul_list{
	display: inline-block;
}
.nodesList{
	counter-reset: nodes;
}
#usersList{
	background-color: #ff0000; 
	width: auto;
}
.navbuttons{
	text-align-last: center;
	background-color:red; 
	margin: 0 auto !important;
	float: right !important;
}
.nodes{
	counter-reset:nodes;
}

/** disc */
.nodes .numCounterDisc::before{
	counter-increment: nodes;
	content:" " counter(nodes,disc) " ";	
}

.nodes .nodes .numCounterDisc::before{
	counter-increment: nodes;
	content:" " counter(nodes, disc) "  ";
}

/** decimal  1,2,3 */
.nodes .numCounter::before{
	counter-increment: nodes;
	content:" " counter(nodes) " ";	
}

.nodes .nodes .numCounter::before{
	counter-increment: nodes;
	content:" " counters(nodes, ".") "  ";
}

/** decimal with brace )  */
.nodes .numCounterBrace::before{
	counter-increment: nodes;
	content:" " counter(nodes) ") ";	
}

.nodes .nodes .numCounterBrace::before{
	counter-increment: nodes;
	content:" " counters(nodes, ".") ")  ";
}

/** lower-alpha )  */
.nodes .numCounterLowerAlpha::before{
	counter-increment: nodes;
	content:" " counter(nodes,lower-alpha) ") ";	
}

.nodes .nodes .numCounterLowerAlpha::before{
	counter-increment: nodes;
	content:" " counters(nodes, ".", lower-alpha) ")  ";
}

/** upper-alpha  */
.nodes .numCounterUpperAlpha::before{
	counter-increment: nodes;
	content:" " counter(nodes,upper-alpha) ") ";	
}

.nodes .nodes .numCounterUpperAlpha::before{
	counter-increment: nodes;
	content:" " counters(nodes, ".", upper-alpha) ")  ";
}

/** upper-roman */
.nodes .numCounterUpperRoman::before{
	counter-increment: nodes;
	content:" " counter(nodes,upper-roman) ") ";	
}

.nodes .nodes .numCounterUpperRoman::before{
	counter-increment: nodes;
	content:" " counters(nodes, ".", upper-roman) ")  ";
}

.panelnew {
    position: relative;
    width: 150px;
}

/* Dima styles*/

#usersPopup{
	background-color: rgb(255, 255, 255);
	border-color: rgb(221, 221, 221);
	box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.176); 
}

#usersPopup .users-at {
	color: rgb(0, 153, 255);
	font-size: 24px;
	font-weight: 700;
	padding-bottom: 0px;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 0px;
}

#usersPopup .users-popup-header{
	/*display: inline-flex;*/
	/*margin-bottom: 225px;*/
    vertical-align: middle;
}

#usersPopup .users-filter {
/*	border: 1px dotted blue; */
	margin-left: 10px;
}

#usersRole {
/*	border: 1px dotted red;
	order: 1;

	flex-grow: 1;
	align-self: flex-end;*/
}

#usersFilter{
/*	border: 1px solid #ccc;
	color: rgb(0, 153, 255);*/
	font-size: 14px;
	font-weight: 400;
	width: 350px;
}

#usersPopupClose{
	background-color: rgb(255, 255, 255) !important;
	display: inline-block;
	margin-left: 12px;
	float: right;

}

#usersPopup .popover-content{
	padding-right: 0px;
}

#usersPopupClose span{
	color: #00aa00 !important;	
	font-size: 16px;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0px;
}

#usersRole{
	color: rgb(51, 51, 51);
	font-size: 14px;
	font-weight: 400;
	padding-right: 0px;
	/*flex-grow: 1;*/
	width: 150px;
	display: inline-block;
}

#usersFilter .user{
/*	background-color: transparent;
	border-bottom: 1px solid #dddddd;
*/	
	display: flex;
	align-items: center;
}

#usersFilter .css-1rhbuit-multiValue .user .user-email {
	display: none;
}

#usersFilter .user .user-avatar{
	display: inline-flex;
	height: 32px;
	width: 32px;
}

#usersFilter .css-1rhbuit-multiValue .user .user-avatar {
	height: 16px;
	width: 16px;
}

#usersFilter .user .user-name{
	color: rgb(51, 51, 51);
	font-size: 14px;
	font-weight: 700;
	margin-left: 6px;
}

#usersFilter .css-1rhbuit-multiValue .user .user-name {
	font-weight: 400;
}

#usersFilter .user .user-nick{
	color: rgb(51, 51, 51);
	font-size: 14px;
	font-weight: 400;
}

#usersFilter .css-1rhbuit-multiValue .user .user-nick {
	display: none;
}

#usersFilter .user .user-email{
	color: rgb(0, 153, 255);
	font-size: 12px;
	font-weight: 400;
	margin-left: 6px;
	text-decoration-line: underline;
}
.content1{
	color: #880000;
}
.content2{
	color: #008800;
}
.content3{
	color: #000088;
}
.content4{
	color: #883300;
}
.content5{
	color: #338800;
}
.content6{
	color: #003388;
}

.node-users-content{
	margin-left: 0.2em;
	font-size: 10px;
}
.user-node-content{
	background-color:#eeeeee;
	margin-left: 0.8em;
}
.user-node-description{
	margin-left: 0.8em;
		
}
.action-link{
	color:#000099;
	text-decoration: underline;
	cursor: pointer;
	padding-right: 15px;
}
.toggleParticipation{
	color:#000099;
	text-decoration: underline;
	cursor: pointer;
	font-size: 10px;	
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
.removeAttach{
	font-weight: bold;
	color:#ff0000;
	text-decoration: underline;
	cursor: pointer;
}
.attach-fileName{
	font-weight: bold;
	word-wrap: break-word;
}
.attach-fileLink{
	text-decoration: underline;
	font-weight: bold;
	color:#008800;
	cursor: pointer;
}
.attachment{
  border-radius: 15px;
  border:solid 1px #cccccc;
  padding-right: 0px;
  margin:4px;
}
.inputComment{
  border-radius: 15px;
  border:solid 1px #cccccc;
  padding: 15px;
  margin: 10px;
}
.inputComment:active{
  border-radius: 15px;
  border:solid 0px #000000;
  padding: 15px;
}
.attachmentHeading{
	text-decoration: underline;
	font-weight: bold;
	font-size: 24px;
}
.commentHeading{
	text-decoration: underline;
	font-weight: bold;
	font-size: 18px;
}
.commentsSection{
	margin:10px !important;
	border-radius: 15px;
	border:solid 1px #cccccc;
}
.commentInfo{
	margin: 15px !important;
}
.commentDateTime{
	float: left;
	margin-left:15px;
}
.commentUserName{
	float: left;
	font-weight: bold;
	color:#008800;
}
.commentDesc{
	margin-left: 15px !important;
}
.commentActions{
	margin-left: 15px !important;
	color:#000088;
	text-decoration: underline;
	cursor: pointer;
}
.badge{
	background-color: #880000 !important;
	vertical-align: top !important;
	top:0px !important;
}
.replies{
	margin-left: 0px !important;
	margin-right: 0px !important;
}
.replyorComment{
	color: #880000;
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
}