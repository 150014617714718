/*Footer*/
.footer-main {
	background-color: #2E2E2E;
	margin-top: 10px;
}
.textwidget {
	font-size: 13px;
}
.footer-title {
	color: #3FA9F5 !important;
	margin-top: 40px;
	font-size: 18px;
	font-weight: bold;
	text-align: start;
}
.text-block{
	text-align: start;
}
.textwidget {

	font-size: 13px;
}
.footer-text-sty {
	text-align: start;
	color: #FFFFFF;
}
.footer-main ul {
	padding: 0px 15px;
	margin-bottom: 40px;
}
.footer-main ul li {
	color: #3FA9F5 !important;
}
.footer-main ul li {
	padding: 0px 15px;
}
.footer-main ul li a {
	color: #fff;
	display: block;
	padding: 5px 0px;
}
.footer-main ul li a:hover {
	color: #3FA9F5;
	text-decoration: none;
}
.footer-strip {
	background-color: #1D1D1D;
	padding: 15px 0px;
}
.footer-info a {
	color: #666666 ;
	font-size: 14px;
	font-weight: bold;
	text-align: start;
}
#footer{
	text-align: start;
}
.footer-strip{
	text-align:start;
}
.footer-info{
	text-align:start;
}
.text-block{
	text-align:start;
	font-size: 16px !important;
}
.footer-title{
	text-align:start;
}
.footer-text-sty{
	text-align:start;
}