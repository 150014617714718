#settingsPage{
	float: left;
}

#settingsPage #test{
	color: #fff;
	background-color: #0277bd;
	border-color: #0277bd;
	font-size: 16px;
	font-weight: 700;
	padding: 5px;
}   

#settingsPopup .section{
	background-color: rgb(255,255,255);
	padding: 20px 0;
	margin: 0 20px;
}

#settingsPoupp .popup-title{
	color: rgb(51,51,51);
	border-bottom: 1px solid #e5e5e5;
	font-size: 24px !important;
	font-weight: 500;
	padding: 15px;
	text-align: start;
}

#settingsPopup .popup-close {
	background: 0 0;
	border: 0;
	color: rgb(255, 53, 71);
	cursor: pointer;
	float: right;
	font-size: 32px;
	font-weight: 700;
	line-height: 1;
	opacity: 1;
	padding: 0;
	margin-right: 15px;
	margin-top: 10px;
}

#settingsPopup .section-title{
	color: rgb(11,75,163);
	font-size: 18px;
	font-weight: 500;
	padding: 5px 0px 0px;
	padding-bottom: 15px;
	margin-top: 0px;

}

#settingsPopup .section .section-title{
	color:  rgb(0, 153, 255);
	padding-bottom:15px;

}

.parameter-group .parameter-label{
	color: rgb(51,51,51);
	font-size: 14px;
	font-weight: 400;
	text-align: left;
}

#settingsPopup .parameter-input{
	border: 1px solid #ccc;
	box-shadow: inset 0 1px 1px rgb(0,0,0,.075);
	border-radius: 4px;
	color: rgb(85, 85, 85);
	font-size: 14px;
	font-weight: 400;
	text-align: start;
	padding: 6px 12px;
	height: 35px;
}

.cls-blue{
	color: rgb(11,75,63,1);
}

.cls-gray{
	color: rgb(128,128,128,1);
}

.cls-green{
	color:rgb(0,120,0,1);
}

.cls-red{
	color: rgb(255,0,0,1);
}

.cls-meroon{
	color: rgb(128,0,0,1);
}

#settingsPopup .parameter-group{
	align-items:center;
	display:flex;
	margin-bottom:20px;
}

#settingsPopup .parameter-group label{
	width:157px;
}

#settingsPopup #uploadPhoto{
	border-color: rgb(2, 119, 189) !important;
	background-color: rgb(2,119,189) !important;
	border-radius: 5px !important;
	color: rgb(255,255,255)!important;
	font-size: 14px !important;
	font-weight: 400 !important;
	position: relative;
   overflow: hidden;
   display: inline-block;
}

#settingsPopup #uploadPhoto .btn-upload{
	padding: 6px 12px !important;
}

#settingsPopup .profile-img {
	margin: 5px auto 10px;
}

#settingsPopup .profilr-edit-icon {
	margin-left: 390px;
	margin-top: -200px;
}

/*primary css*/
#settingsPopup .section .parameter-select{
	border: 1px solid #ccc;
	background-color: rgb(255,255,255);
	box-shadow: inset 0 1px 1px rgb(0,0,0,.075);
	border-radius: 4px;
	color: rgb(85, 85, 85);
	font-size: 14px;
	font-weight: 400;
	text-align: start;
	padding: 6px 12px;
	width: 70%;
	margin-left: auto;
	height: 35px;
}


#settingsPopup .parameter-switch.switchToggle {
	cursor: pointer;
	text-indent: -9999px;
	width: 75px;
	max-width: 75px;
	height: 30px;
	background: #d1d1d1;
	display: block;
	border-radius: 6px;
	position: relative;
}

.label {
	display: inline-block;
	max-width: 100%;
	margin-bottom: 5px;
	font-weight: 700;
}

.switchToggle input + label:before, .switchToggle input + input + label:before {
	content: 'OFF';
	position: absolute;
	top: 5px;
	left: 35px;
	width: 26px;
	height: 26px;
	border-radius: 90px;
	transition: 0.3s;
	text-indent: 0;
	color: #fff;
}

:after, :before {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

#settingsPopup .Other{
	margin-left: 35px;
}

#restoreDefaults{
	background-color: rgb(2, 119, 189) !important;
	border-color: rgb(2, 119, 189) !important;
	color: rgb(255, 255, 255);
	font-size: 14px !important;
	float:right;
	font-weight: 400;
	margin-top:-40px;
	padding: 6px 12px !important;
}

#settingsPopup .parameter{
	width:40%;
}

#settingsPopup .parameter .parameter-input{
	cursor: pointer;
	width: 71%;
	height: 35px;
} 

 /*.color-picker{
	margin-left: -25px;
	height: 14px;
	padding-top: 11px;
}*/

#settingsPopup .section .delete-warning{
	color: rgb(51,51,51);
	font-size: 14px;
	font-weight: 400;
	padding-bottom: 10px;
	text-align: left
}

#settingsPopup .section .switchToggle{
	display: inline-flex;
	margin-left: auto;
}

#settingsPopup .section:not(:last-child){
	border-bottom: 1px solid #d1d1d1;

}

#settingsPopup .settings-mbody{
	max-height: calc(100vh - 125px);
	overflow-y: auto;
}

#settingsPopup .modal-body{
	position: relative;
	padding: 0px;
}

#settingsPopup .section .danger{
	color:rgb(255, 0, 0);
}

#settingsPopup .section .delete-warning{
	color: rgb(51,51,51);
	font-size: 14px;
}

#settingsPopup #deleteAccount{
	font-size: 14px !important;
	font-weight: 400;
}

/*color picker styles*/

.color-picker-palette{
	position: absolute;
	z-index: 100;
}

.color-picker-cover{
	bottom: 0px;
	left: 0px;
	position: fixed;
	right: 0px;
	top: 0px;
}

.color-picker-color-background {
	background-color: #808080;
	border-radius: 4px;
	height: 28px;
	margin-left: 123px;
	margin-top: 3px;
	position: absolute;
	width: 28px;
}

