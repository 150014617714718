/*priceing styles*/
.pricing-title {
	padding: 20px 0px;
	text-align: center;
}
.pricing-title h2 {
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 20px;
}
.price-cols [class*="col-"] {
	padding: 0 25px;
}
 .price-box {
	border:1px solid #999999;
	padding: 25px; 
	margin-bottom: 30px;
	min-height: 500px;
	-moz-box-shadow: 10px 10px 5px #8b8b8b;
	-webkit-box-shadow: 10px 10px 5px #8b8b8b;
	box-shadow: 10px 10px 5px #8b8b8b;
}
.price-box h3.pb-title {
	color: #666666;
	font-size: 20px;
	text-align: center;
	
}
.price-box .title-shadow {
	color: #333333 !important; 
	text-shadow: 1px 1px #6b6b6b;
}
.price-box .price-cost h2 {
	color: #0099ff;
	font-size: 32px;
	text-transform: uppercase;
	margin-bottom: 0px;
}
.price-box .price-cost p {
	color: #808080;
	font-size: 14px;
	text-transform: uppercase;
	
}
.price-box .price-text > p {
	font-size: 16px;
	line-height: 32px;
	text-align: center;

}

.no-switch-input {
	padding: 54px 0px;
}

.price-btn span.text-gray {
	color: #6b6b6b;
}

.switch-prices {
	padding: 15px;
	text-align: center;
}

.switch-prices .switch label {
	cursor: pointer;
	font-size: 14px;
	font-weight: normal;
}

.switch-prices .switch label input[type="checkbox"] {
	opacity: 0;
	width: 0;
	height: 0;
}

.switch-prices .switch label .switchBtn {
	background-color: #aeaeae;
	border-radius: 10px;
	cursor: pointer;
	content: "";
	display: inline-block;
	height: 15px;
	position: relative;
	margin: 0px;
	margin-right: 0px;
	vertical-align: middle;
	width: 40px;
	-webkit-transition: background 0.3s ease;
	transition: background 0.3s ease;
}

.switch-prices .switch label .switchBtn::after {
	position: absolute;
	top: -2px;
	left: -.3125rem;
	display: inline-block;
	content: "";
	background-color: #f1f1f1;
	border-radius: 50%;
	-webkit-box-shadow: 0 0.0625rem 0.1875rem 0.0625rem rgba(0,0,0,0.4);
	box-shadow: 0 0.0625rem 0.1875rem 0.0625rem rgba(0,0,0,0.4);
	width: 18px;
	height: 18px;
	-webkit-transition: left 0.3s ease,background 0.3s ease,-webkit-box-shadow 1s ease;
	transition: left 0.3s ease,background 0.3s ease,-webkit-box-shadow 1s ease;
	transition: left 0.3s ease,background 0.3s ease,box-shadow 1s ease;
	transition: left 0.3s ease,background 0.3s ease,box-shadow 1s ease,-webkit-box-shadow 1s ease;
}

.switch-prices .switch label input[type="checkbox"]:checked + .switchBtn {
	background-color: #b2e0ff;
}

.switch-prices .switch label input[type="checkbox"]:checked + .switchBtn::after {
	left: 24px;
	background-color: #0099ff;
}

.switch-prices .switch .s-monthly {
	font-size: 14px;
	font-weight: bold;
	color: #6b6b6b;
	margin:5px;
}
.switch-prices .switch .s-annually {
	font-weight: bold;
	color: #333333;
	font-size: 14px;
	text-shadow: 1px 1px #6b6b6b;
}

#annually {
	font-weight: 700;
}



#monthly {
	font-weight: 700;
}

.selected{
	color: rgb(51,51,51) !important;
	text-shadow: rgb(107,107,107) 1px 1px 0px !important;

}
#trialWeeks,#trialUsers{
	margin:3px;
	margin-top: 0px;
	margin-left: 60px;
}
/*#incUser1, #incUser2 ,#incUser3, #incUser4,#decUser1, #decUser2, #decUser3, #decUser4 {
	margin-top: 3px;
	margin-right: 66px;
	cursor: pointer;
	width:0px;
}*/
/*#decUser1, #decUser2, #decUser3, #decUser4{
	padding-top: 3px;
	margin-right: 66px;
	cursor: pointer;
	width:0px;

}*/


.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.make-a-deal .modal-header .close {
	opacity: 1;
	font-size: 32px;
}
.make-a-deal .modal-body {
	padding: 30px;
}
.numbers-rows label {
	font-weight: bold;
	display: block;
	float: left;
	text-align: right;
	padding: 10px 10px 0 0;
	width: 57px;
	font-size: 40px;
}
.numbers-rows input[type=text] {
	float: left;
	width: 100px;
	height: 70px;
	font-weight: bold;
	font-size: 40px;
	padding: 3px 0 0 0;
	text-align: center;
}
/*.numbers-rows .button1, .numbers-rows .button2, .numbers-rows .button3, .numbers-rows .button4 {
	text-indent: -9999px;
	cursor: pointer;
	width: 30px;
	height: 30px;
	text-align: center;
}*/
.numbers-rows .ch-up {
	background: url({process.env.PUBLIC_URL + "/images/command-icon/ch_up.png"}) no-repeat;
	background-size: cover;
	float: left;
	margin-top: 3px !important;
	margin-left: 5px !important;
	
}
.numbers-rows .ch-down {
	background: url({process.env.PUBLIC_URL + "/images/command-icon/ch_down.png"}) no-repeat;
	background-size: cover;
	float: left;
	margin-top: 35px;
	margin-left: -30px;
}
.inc:hover, .dec:hover {
		-webkit-filter: invert(43%) sepia(27%) saturate(5145%) hue-rotate(183deg) brightness(102%) contrast(111%);
		 filter: invert(43%) sepia(27%) saturate(5145%) hue-rotate(183deg) brightness(102%) contrast(111%);
}
.inc-dis, .dec-dis, .inc-dis:hover, .dec-dis:hover  {
		filter: brightness(0.5) invert(.5) sepia(0.5) hue-rotate(50deg) saturate(50%);
	   -webkit-filter: brightness(0.5) invert(.5) sepia(0.5) hue-rotate(50deg) saturate(50%);
} 
.numberLabel {
	font-size: 16px;
}
.no-label {
	margin-left: 60px;
}
.deal-popup .form-group > label {
	font-size: 16px;font-weight: normal;
}
.group-text-label{
	font-weight: 400;
}
.mar-tb-5 {
	margin-top: 5px;
	margin-bottom: 5px;
}
.marTop30 {
	margin-top: 30px;
}
.mBtm20 {
	margin-bottom: 20px;
}
#unlimited{
	color: #ffffff !important;
	background-color: #ff6f00!important;
	border-color: #ff6f00 !important;
	font-weight: bold !important;
	font-size: 16px !important;
	margin-left: 15px;
}
/*pricing page styles*/
.btn-block {
	display: block;
	width: 100%;
}

 .btn-lg {
	padding: 10px 16px !important;
	font-size: 18px !important;
	line-height: 1.3333333 !important;
	border-radius: 2px !important;
}
#pricingName{
	font-size: 28px;
	font-weight:bold;
	padding: 20px 0;
	text-align: center;
}

#planFree {
	border-color: rgb(153, 153, 153);
	box-shadow: rgb(139, 139, 139) 10px 10px 5px 0px;	
}

#planFree .plan-limits{
	text-align: center;
	margin-bottom: 20px;
	padding: 54px 0px;
	line-height: 34px;
}

.plan-name{
	font-size: 20px;
	text-align: center;
	font-weight:500;
	color:#666666;
	
}

.plan-price{
	color: #0099ff;
	font-size: 32px;
	font-weight: 500;
	text-transform: uppercase;
}

.plan-term{
	color: #808080;
	font-size: 400;
	text-align: center;
	text-transform: uppercase;
}

.limit-value{
	color: #333333;
	font-size: 16px;
	font-weight: bold;
}

.limit-name{
	color: #333333;
	font-size: 16px;
	font-weight: 400;
}

#planUnlimited {
	border-color:rgb(153, 153, 153);
	box-shadow: rgb(139, 139, 139) 10px 10px 5px 0px;

}

#planUnlimited .plan-limits{
	text-align: center;
	margin-bottom: 20px;
	line-height: 32px;
   
}

.plan-limits .limit-name{
	margin-left: 0.2em;
}	

#planUnlimited .plan-name{
	color: #333333;
	font-weight: 400;
	text-shadow: #6b6b6b 1px 1px 0px;

}

#planUnlimited .plan-price{
	font-weight: 400;
}

 #planUnlimited .plan-price-units{
	color: #808080;
	font-size: 14px;
	font-weight:400;
	text-transform: uppercase;
	text-align: center;
 }

/*#unlimitedPopup{
	background-color: #ffffff;
	border-color: #dddddd;
	box-shadow:rgb( 0, 0, 0, 0.176) 0px 6px 12px 0px;
 }*/
 #unlimitedPopup .popup-title{
	font-size: 24px;
	font-weight: 500;

 }

#planUnlimited .trial-line .trial-line-text{
	color: #6b6b6b;
}

#planUnlimited  .trial-line-link{
	color:#0099ff;
	background-color: transparent;
	border: 1px solid transparent;
	cursor: pointer;
	padding-left: 0px;
	clear: both;
	text-decoration:underline;
	margin-left: 0.2em;
}
#planUnlimited .deal-line-link{
	color:#0099ff;
	background-color: transparent;
	border: 1px solid transparent;
	cursor: pointer;
	padding-left: 0px;
	clear: both;
	text-decoration:underline;
	margin-left: 0.2em;
}

#planUnlimited .deal-line-text{
	color: #6b6b6b;
}

#unlimitedPopup .group-text-label{
	font-size: 16px !important;
	font-weight: 400!important;
}

#unlimitedPopup .group-text-input{
	display: block;
	width: 100%;
	height: auto;
	padding: 6px 12px;
	font-size: 18px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
}

#unlimitedPopup .popup-close{
	color: rgb(255, 53, 71);
	font-size: 32px;
	font-weight: 700;
	opacity: 1;
	float: right;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0;
	margin-top: -2px;
	line-height: 1;
}

/*#trialPopup{
	background-color: rgb(255, 255, 255);
	border-color: rgb(221, 221, 221);
	box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px 0px;
}*/

#trialPopup .popup-title{
	font-size: 24px;
	font-weight: 500;
	text-align: start;
	color: rgb(51,51,51);
}

#trialPopup .group-numeric-label{
	font-size: 16px;
	margin: 0 0 10px;
}

#trialPopup .group-numeric-input{
   font-size: 40px;
   font-weight: 700;
   margin-bottom: 20px;

}

#trialWeeks {
	float: left;
	width: 100px;
	height: 70px;
	padding: 3px 0 0 0;
	text-align: center;
	margin-bottom: 20px;
}

#trialUsers{
	float: left;
	width: 100px;
	height: 70px;
	padding: 3px 0 0 0;
	text-align: center;
}

#trialPopup .group-text-input{
	color: rgb(85,85,85);
	font-size: 18px;
}

#trialPopup .popup-close{
	color: rgb(255, 53, 71);
	font-size: 32px;
	font-weight: 700;
	opacity: 1;
	float: right;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0;
	margin-top: -2px;
	line-height: 1;
}

  #trialPopup  .group-text-input{
	display: block;
	width: 100%;
	height: auto;
	padding: 6px 12px;
	font-size: 18px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
}

/*#dealPopup{
	background-color: rgb(255, 255, 255);
	border-color:rgb(221, 221, 221);
	box-shadow:rgb(0, 0, 0, 0.176) 0px 6px 12px 0px;
}*/

#dealPopup .popup-title{
	font-size: 24px;
	font-weight: 500;
	text-align: start;
	color: rgb(51,51,51);
}

#dealPopup .group-numeric-label{
	font-size: 16px;
	margin: 0 0 10px;
}

#dealPopup .group-numeric-input{
   font-size: 40px;
   font-weight: 700;

}

#dealPopup .group-text-input{
	color: rgb(85, 85, 85);
	font-size: 18px;
}

#dealPopup  .popup-close{
	color: rgb(255, 53, 71);
	font-size: 32px;
	font-weight: 700;
	opacity: 1;
	float: right;
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0;
	margin-top: -2px;
	line-height: 1;
}

#dealUsers{
	float: left;
	width: 100px;
	height: 70px;
	padding: 3px 0 0 0;
	text-align: center;
	margin: 3px;
	margin-top: 0px;
	margin-left: 60px;
}

#dealPopup .group-text-input{
	display: block;
	width: 100%;
	height: auto;
	padding: 6px 12px;
	font-size: 18px;
	line-height: 1.42857143;
	color: #555;
	background-color: #fff;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
}

#dealPrice {
	float: left;
	padding: 3px 0 0 0;
	width: 100px;
	height: 70px;
	text-align: center;
	margin: 3px;
	margin-top: 0px;
}

 .group-numeric-units {
	display: block;
	float: left;
	text-align: right;
	padding: 10px 10px 0 0;
	width: 75px;
	font-size: 40px;
}

 .group-numeric-arrow-up  {
	float: left;
	margin-top: 3px;
	margin-left: 5px;
	cursor: pointer;
}

.group-numeric-arrow-down{
	float: left;
	margin-top: 37px;
	margin-left: -31px;
	cursor: pointer;
}

.inc-dis,.dec-dis{
	cursor: default !important;
}

#signUp{
	font-weight: 400!important;
}

.text-group{
   font-size: 16px !important;
   margin-bottom: 15px;
}

.numeric-group{
	text-align: center;
}

.group-text-error{
	color: rgb(255, 53, 71);
	font-size: 14px;
}
/*logo style*/
#pricingPage #logo{
	align-items: center;
	color: rgb(51,51,51)!important;
	display: flex;
	font-size: 24px !important;
	font-weight: 700;
	margin-right: auto;
	text-decoration: none;

}

#pricingPage #logo .logo-mn{
	color: rgb(0, 153, 255) !important;
	font-size: 28px;
	font-weight: 700;
	margin-top: -3px;
}

#pricingPage .landing-header{
	border-bottom: 1px solid #0099ff !important;
	background-color: #f8f8f8;
	display: flex;
	flex-direction: row;
	padding: 8px 15px;
}

#pricingPage .landing-header .lr-links{
	align-items: center;
	display: flex;
	margin-left: auto !important;
	padding-bottom: 5px !important;
	padding-top: 5px !important;
}


