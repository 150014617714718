.newRole{
	outline: solid 1px #00ff00;
}
.roles{
	width: 100%;
	float: left;
}

.pull-right{
	top:0px !important;
	float: right;
}
.newRight{
	outline: solid 1px #00ff00;
}


		body{
			color: rgb(51, 51, 51);
			font-family: sans-serif;
			font-size: 14px;
		}

		.noselect {
			/*cursor: default;*/
			user-select: none; /* supported by Chrome and Opera */
			-webkit-user-select: none; /* Safari */
			-khtml-user-select: none; /* Konqueror HTML */
			-moz-user-select: none; /* Firefox */
			-ms-user-select: none; /* Internet Explorer/Edge */
		}

		#rolesRightsPopup {
			font-size: 16px;
			width: 550px;
			padding-top: 5px;
			padding-left: 5px;
		}

		#rolesRightsPopup [contenteditable="true"]{
			background-color: rgb(230, 245, 255);
		}

		#rolesRightsPopup .roles-rights{
			border: 1px solid rgb(128, 128, 128);
			display: flex;
			flex-direction: row;
		}

		#rolesRightsPopup .rights-container {
			border-right: 1px solid rgb(128, 128, 128);
		}

		#rolesRightsPopup .rights-header {
			align-items: center;
			background-color: rgb(251, 251, 251);
			color: rgb(115, 115, 115);
			display: flex;
			height: 56px;
			font-weight: 700;
			padding-left: 0.5em;
			text-align: start;
			text-transform: uppercase;
		}

		#rolesRightsPopup .right-flex {
			align-items: center;
			border-top: 1px solid rgb(128, 128, 128);
		 	display: flex;
			height: 60px;
		}

		#rolesRightsPopup .right {
			height: 2em;
			padding-left: 0.5em;
			padding-right: 0.5em;
		}

		#rolesRightsPopup .right .right-name {
			white-space: nowrap;
		}

		#rolesRightsPopup .right .right-description {
			color: rgb(115, 115, 115);
			font-size: 0.875em;
			white-space: nowrap;
		}

		#rolesRightsPopup .roles-container {
			overflow: hidden;
			width: 100%;
			padding-top: 1px;
		}

		#rolesRightsPopup .roles-header {
			align-items: center;
			border-bottom: 1px solid rgb(128, 128, 128);
			background-color: rgb(251, 251, 251);
			color: rgb(115, 115, 115);
			display: flex;
			font-weight: 700;
			justify-content: center;
			height: 30px;
			text-transform: uppercase;
		}

		#rolesRightsPopup .roles {
			display: flex;
			flex-direction: row;
			overflow-x: scroll;
			overflow-y: hidden;
			white-space: nowrap;
  		}

		#rolesRightsPopup .roles .role {
			border-right: 1px solid rgb(128, 128, 128);
			text-align: center;
		}

		#rolesRightsPopup .role .role-header {
			display: flex;
			flex-direction: row;
			padding-left: 0.2em;
			padding-right: 0.2em;
		}

		#rolesRightsPopup .role .role-header .role-move{
			align-items: center;
			background-color: rgb(251, 251, 251);
			color: rgb(115, 115, 115);
			/*cursor: default;*/
			display: flex;
			justify-content: center;
			font-weight: 400;
			opacity: 0;
		}

		#rolesRightsPopup .role .role-header:hover .role-move{
			cursor: move;
			opacity: 1;
		}

		#rolesRightsPopup .role .role-header .role-delete{
			align-items: center;
			color: rgb(0, 153, 255);
			/*cursor: default;*/
			display: flex;
			justify-content: center;
			font-weight: 400;
			font-size: 12px;
			opacity: 0;
		}

		#rolesRightsPopup .role .role-header:hover .role-delete{
			cursor: pointer;
			opacity: 1;
		}

		#rolesRightsPopup .role .role-header .role-name {
			display: flex;
			height: 25px;
			align-items: center;
			justify-content: center;
			padding-left: 0.2em;
			padding-right: 0.2em;
		}

		#rolesRightsPopup #addRole {
			color: rgb(0, 153, 255);
			cursor: pointer;
			float: right;
			font-size: 14px;
			text-decoration: underline;
		}

		#rolesRightsPopup .role-rigth {
			align-items: center;
			border-top: 1px solid rgb(128, 128, 128);
			cursor: pointer;
			display: flex;
			justify-content: center;
			height: 60px;
		}
