
#tryDemo{
	color: #ffffff !important;
	background-color:#ff6f00!important;
	border-color:#ff6f00 !important;
	font-weight:bold;
	font-size: 16px !important;
	margin-left: 15px;
}

#help{
	color: rgb(0, 153, 255);
	font-size: 16px;
	margin-left: 15px;
}
#pricing{
	color: rgb(0, 153, 255) !important;
	font-size: 16px !important;
}

#landingPage #logo{
	color: rgb(51,51,51)!important;
	display: flex;
	font-size: 24px !important;
	font-weight: 700;
	align-items: center;
	text-decoration: none;
	margin-right: auto;
}

#landingPage #logo.logo-mn{
	color: rgb(0, 153, 255) !important;
	font-size: 28px;
	font-weight: 700;
	margin-top: -3px;
}
#logIn{
	font-weight: bold;
}

#landingPage .landing-header{
	border-bottom: 1px solid #0099ff !important;
	display: flex;
	flex-direction: row;
	padding: 8px 15px;
	background-color: #f8f8f8;
}

#landingPage .landing-header .lr-links{
	align-items: center;
	display: flex;
	margin-left: auto !important;
	padding-bottom: 5px !important;
	padding-top: 5px !important;
}

.landing-header {
	padding: 8px 15px;
	background-color: #f8f8f8;
	border-bottom: 1px solid #0099ff !important;
}

.lheader-fixed {
	position: fixed;
	width: 100%;
	top: 0px;
	z-index: 99;
	box-shadow: 0px 4px 9px -2px #838080;
}

.landing-header .lh-left {
	font-size: 24px !important;
	font-weight: 600;
	cursor: pointer;
	color: #333333 !important;

}

.lh-left span.cls-cdt {
	font-size: 26px;
}

.lr-links {
	padding: 0px !important;
	margin: 0px !important;
}

.lr-links li {
	list-style-type: none;
	display: inline-block;
}

.lr-links li > .button-link {
	font-size: 16px;
	font-weight: normal;
	padding: 6px 12px;
}

@media (max-width:767px) {
	.lh-left span.visible-xs {
		display: inline-block !important;
	}
	.lr-links li > .button-link {
	padding: 6px;
	}
}

