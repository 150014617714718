@font-face {
	font-family: 'Arial';
	src: url('../bootstrap/fonts/ArialTh.ttf');
	font-weight: normal;
	font-style: normal;
}
body {
	font-family: "Arial", sans-serif;
	color: #333333;
}



/*Start cdt-sidenav*/
.sidenav {
	position: fixed;
	width: 0;
	height: 100%;
	top: 0;
	left: 50px;
	z-index: 1;
	overflow-x: hidden;
	transition: 0.5s;
	padding-top: 50px;
	border-right: 1px solid #ddd;
}
.lm-nav-title .closebtn {
	position: absolute;
	top: 5px;
	right: 10px;
	font-size: 24px !important;
	font-weight: 700;
	color: red;
}
.lm-nav-title {
	background-color: #eee !important;
	padding: 13px!important;
	position: absolute;
	top: 0px;
	width: 100%;
}
.lm-nav-title h4 {
	font-size: 24px !important;
	display: block;
	width: 100%;
	padding: 0px;
	margin: 0px;
	color: #0099ff !important;
}
#cdt-wrapper {
	transition: margin-left .5s;
	padding-left:  50px;
}
.left-side-bar {
	position: fixed;
	top: 0px;
	background-color: #f1f1f1;
	margin: 0;
	padding: 0;
	width: 50px;
	height: 100%;
	overflow: auto;
}
.links-icons {
	padding: 0px;
	margin: 0px;
}
.left-side-bar .links-icons {
	padding: 0px;
	margin: 0px;
}
.left-side-bar .links-icons li {
	list-style-type: none;
}
.left-side-bar .links-icons li > a {
	display: block;
	color: #333;
	padding: 9px 5px;
	text-align: center;
	font-size: 24px !important;
	border-bottom: 1px solid #ddd;
	text-decoration: none;
}
.left-side-bar .links-icons li > a:hover {
	color: blue;
	background-color: #fff;
}
.left-side-bar .links-icons > li.active > a, .left-side-bar .links-icons > li.active > a:focus, .left-side-bar .links-icons > li.active > a:hover {
	color: blue;
	background-color: #fff;
}
.left-side-bar .links-icons li > a > i.fa {
	font-size: 24px !important;
}
/*End cdt-sidenav*/
/*Header start*/
.cdt-header {
	background-color: #eee;
	padding: 5px 15px;
}
.cdt-bc-btn .breadcrumb {
	padding: 8px 0px;
	margin-bottom: 0px;
	list-style: none;
	background-color: transparent;
	border-radius: 4px;
	display: inline-block;
}
.breadcrumb li > a {
	color: blue;
}
.cdt-top-right {
	padding: 0px;
	margin: 0px;
}
.cdt-top-right > li {
	display: inline-block;
	list-style-type: none;
}
.cdt-top-right li > a {
	display: inline-block;
	padding: 10px;
	border-left: 1px solid #cacaca;
}
#custom-search-input {
	margin:0;
	padding: 0;
}
#custom-search-input .search-query {
	padding-right: 3px;
	padding-right: 4px;
	padding-left: 3px;
	padding-left: 4px;
	margin-bottom: 0;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
}
#custom-search-input button {
	border: 0;
	background: none;
	padding: 2px 5px;
	margin-top: 2px;
	position: relative;
	left: -28px;
	margin-bottom: 0;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	color:#0099ff;
	box-shadow: none;
	background-color: transparent;
}
.search-query:focus + button {
	z-index: 3;   
}
.lm-list-group a.list-group-item {
	background-color: #f8f8f8;margin-bottom: 8px;border-radius: 4px;
}
.lm-list-group a.list-group-item.active {
	background-color: #dcedc8;
	border-color: #dcedc8;
	color: #333333;
}
.list-group-item.active > .lg-badge, .lg-badge {
	padding: 0px;
	font-size: 12px;
	font-weight: 700;
	color: red;
	background-color: transparent;
	padding: 3px 0px;
}
.right-dd-menu {
	right: 0;
	left: auto;
	padding: 0px;
}
.right-dd-menu li > a {
	display: block;
	padding: 8px;
	border-bottom: 1px solid #ddd;
}
.right-dd-menu li > a i.fa {
	min-width: 20px;
}
.cls-lightblock, .cls-lightblock:hover {
	background-color: #333333 !important;
	color: #fff !important;
}
.bc-link {
	background-color: yellow;
	display: inline-block;
	padding: 3px 6px;
	border: 1px solid #333333;
	color: #000;
	border-radius: 6px;
}
.bc-link i.fa {
	color: green;
}
.cdt-wrapper-cnt {
	padding: 15px;
	position: relative;
}
/*End Header page*/
/*Start tree View*/
ul, #myUL {
	/*list-style-type: none;*/
}
#myUL {
	margin: 0;
	padding: 0;
}
#myUL li {
	position: relative !important;
	line-height: 24px !important;
}
.carets {
	cursor: pointer;
	-webkit-user-select: none; /* Safari 3.1+ */
	-moz-user-select: none; /* Firefox 2+ */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;
	font-size: 16px;
}
.carets::before {
	font-family: "FontAwesome";
	content: "\f0da";
	color: black;
	display: inline-block;
	margin-right: 6px;
	position: absolute;
	left: -10px;
	font-size: 18px;
}
.carets-down::before {
	-ms-transform: rotate(90deg); 
	-webkit-transform: rotate(90deg); 
	transform: rotate(90deg);  
}
.nested {
	display: none;
	padding: 0px;
	margin-left: 15px;
}
#myUL .active {
	display: block;
}
/*numbering*/
.legal-list {
	counter-reset: section;
}
.legal-list li.order_list:before {
	counter-increment: section;
	content: counter(section) ". ";
	margin: 0 0.5em 0 0;
}
.legal-list ul.lists {
	counter-reset: clause;
	list-style: none outside none;
	text-indent: 0;
	/*margin-left: -30px;*/
}
.legal-list ul.lists li {
	counter-increment: clause;
}
.legal-list ul.lists li:before {
	content: counter(section) "." counters(clause, ".") ". ";
	margin: 0 0.5em 0 0;
}
/*End tree View*/
/*Start Tree content*/
.scope-box {
	padding: 0px;
	background: #eee;
	margin-bottom: 15px;
}
.sb-table {
	padding: 0px 10px 10px;
}
.sb-table .table {
	margin-bottom: 0px;
}
span.red-label {
	background-color: red;border:1px solid #666;float: right;margin-top: -20px;
}
.scope-box h3.box-title {
	padding: 15px;margin: 0px;font-size: 18px;color: #0099ff;display: inline-block;
}
.cols-space {
	padding: 0px 15px 10px;
}
.cols-space [class*="col-"] {
padding: 0px 7.5px;
}
.scope-box-content {
	padding: 15px;background-color: #ffffff;
}
.tr-bg {
	background-color: #ddd;
}
/*End Tree content*/
/*Start popup*/
.popup-open {
	position: absolute;top: 15px;right: 0px;
}
.popup-open a#addClass {
	font-size: 24px !important;
	color: #333333;
}
.popup-box {
	background-color: #e5e5e5;
	border: 1px solid #b0b0b0;
	box-shadow: 0.0625rem 0.125rem 0.1875rem rgba(0,0,0,0.2);
	top: 55px;
	display: none;
	height: 500px;
	position: fixed;
	right: 15px;
	width: 250px;
}
.popup-box-on {
	display: block !important;
}
.popup-box .popup-head {
	background-color: #fff;
	clear: both;
	color: #7b7b7b;
	display: inline-table;
	font-size: 22px;
	padding: 10px;
	width: 100%;
}
.popup-box .popup-head .popup-head-left {
	margin-top: 3px;
}
.popup-box .popup-head .popup-head-right {
	margin: 0;
}
.popup-messages-footer {
	background: #fff none repeat scroll 0 0;
	bottom: 0;
	position: absolute;
	width: 100%;
}
.popup-messages-footer .btn-footer {
	overflow: hidden;
	padding: 2px;
	width: 100%;
}
.popup-messages-footer .swith-cases {
	padding: 5px;border-bottom: 1px solid #333;border-top: 1px solid #333333;
}
.popup-messages-footer .swith-cases .swith-title {
	padding-top: 5px;color: #333333;
}
.btn-footer .popup-ftable {
	margin-bottom: 0px;
}
.btn-footer .popup-ftable > tbody > tr > td {
	vertical-align: middle;
	font-size: 18px;
	text-align: center;
	line-height: 14px;
	border-top: none;
	padding: 0px;
}
.btn-footer .popup-ftable > tbody > tr > td > a {
	display: block;
	color: #333333;
	padding: 5px 10px;
	line-height: 14px;
}
.btn-footer .popup-ftable > tbody > tr > td:hover, .btn-footer .popup-ftable > tbody > tr > td:hover a {
	background-color: #e3f2fd;
	color: #64b5f6;
}
.btn-footer .popup-ftable > tbody > tr > td:nth-last-child(2) {
	border-right: 1px solid #333;
}
.btn-footer .popup-ftable > tbody > tr > td.active , .btn-footer .popup-ftable > tbody > tr > td.active > a  {
	background-color: #e3f2fd;
	color: #64b5f6;
}
.btn-footer-ul li a.active {
	background-color: #e3f2fd;
	color: #64b5f6;
}
.btn-footer-ul li:nth-last-child(2) {
	border-right: 1px solid #333;
}
.popup-box .popup-messages {
	/*background: #3f9684 none repeat scroll 0 0;*/
	height: 350px;
	overflow: auto;
	padding: 5px;
}
.popup-head-right .btn-group {
	display: inline-flex;
	margin: 0 8px 0 0;
	vertical-align: top !important;

}
.chat-header-button {
	background: transparent none repeat scroll 0 0;
	border: 1px solid transparent;
	border-radius: 50%;
	font-size: 18px;
	height: 24px;
	width: 24px;
	color: red;
}
.popup-head-right .btn-group .dropdown-menu {
	border: medium none;
	min-width: 122px;
	padding: 0;
}
.popup-head-right .btn-group .dropdown-menu li a {
	font-size: 12px;
	padding: 3px 10px;
	color: #303030;
}
.popup-listbox a.list-group-item {
	padding: 5px 8px;
	margin-bottom: 3px;
	color: #333333;
}
.popup-listbox a.list-group-item .pop-lg-left {
	float: left;
	width: 30px;
	margin-top: 5px;
}
.popup-listbox a.list-group-item .pop-lg-left > i.fa {
	font-size: 24px !important;
}
.popup-listbox a.list-group-item .popup-lg-right {
	padding-left: 30px;
	font-size: 12px;
	line-height: 1.1;
}
.popup-listbox a.list-group-item.active {
	background-color: #e3f2fd;
	border-color: #e3f2fd;
	color: #333333;
}
.popup-listbox a.list-group-item.active  i.fa {
	color: #64b5f6;
}
.switchToggle input[type=checkbox]{height: 0; width: 0; visibility: hidden; position: absolute; }
.switchToggle label {cursor: pointer; text-indent: -9999px; width: 70px; max-width: 70px; height: 30px; background: #d1d1d1; display: block; border-radius: 6px; position: relative; }
.switchToggle label:after {content: ''; position: absolute; top: 2px; left: 2px; width: 26px; height: 26px; background: #fff; border-radius: 90px; transition: 0.3s; }
.switchToggle input:checked + label, .switchToggle input:checked + input + label  {background: #3e98d3; }
.switchToggle input + label:before, .switchToggle input + input + label:before {content: 'OFF'; position: absolute; top: 5px; left: 35px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {content: 'ON'; position: absolute; top: 5px; left: 10px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {left: calc(100% - 2px); transform: translateX(-100%); }
.switchToggle label:active:after {width: 60px; } 
.toggle-switchArea { margin: 10px 0 10px 0; }
/*end popup*/

/*Stat Comments*/
.comments-navtabs {
	padding-left: 30px;
	padding-right: 30px;
}
.comments-navtabs .nav-tabs {
	border-bottom: 0px;
	display: inline-block;
}
.comments-navtabs .btn.text-right {
	float: right;
}
.comments-navtabs .nav-tabs > li > a {
	margin-right: 0px;
	line-height: 1.42857143;
	border: 1px solid #333333;
	border-radius: 0;
	background-color: #fff;
	color: #333;
	padding: 6px 12px;
}
.comments-navtabs .nav-tabs > li.active > a, .comments-navtabs .nav-tabs > li.active > a:focus, .comments-navtabs .nav-tabs > li.active > a:hover {
	color: #333;
	background-color: #90ee90;
	border: 1px solid #333333;
}
.comments-navtabs .nav-tabs > li {
	margin-bottom: 0px;
}
.cb-content .user-icon {
	float: left;width: 50px;
}
.cb-content .user-content {
	padding-left: 50px;
	padding-top: 0px;
	line-height: 1.5;
}
.user-content p {
	color: #333333 !important;font-weight: 700;margin-bottom: 0px;font-size: 12px;
}
.user-content p > span {
	color: #808080 !important;font-weight: normal;font-size: 12px;
}
.user-sub-content p {
	color: #333333 !important;font-weight: normal;font-size: 12px;
}
.user-right-icons {
	position: absolute;right: 10px;top: 5px;
}
.user-right-icons .uri-list {
	padding: 0px;
	margin: 0px;
}
.user-right-icons .uri-list li {
	display: inline-block;
	list-style-type: none;
}
.user-right-icons .uri-list li a {
	display: inline-block;
	padding: 0px !important;
}
.reslove-icon {
	position: absolute;
	bottom: 20px;
	right: -20px;
	font-size: 20px;
	color: green;
}
.nested-content {
	background-color: #fff;
	font-size: 12px;
	color: #808080;
	padding: 8px;
	margin-bottom: 0px;
}
.nested-pbody {
	margin-top: 5px;
	margin-left: 15px;
}
.nested-content p {
	margin-bottom: 0px;
	color: #808080;
	font-size: 12px;
}
.nested-content .checkbox {
	margin-bottom: 0px;
	margin-top: 5px;
}
.cb-label {
	color: green;
}
a.choosen-answer {
	background-color: #d2f8d2 !important;
}
.comment-box-panels {
	padding: 0px 30px 15px;
}
.comment-box-panels .panel-group,
.comment-box-panels .panel-group .panel,
.comment-box-panels .panel-group .panel-heading,
.comment-box-panels .panel-group .panel-heading a,
.comment-box-panels .panel-group .panel-title,
.comment-box-panels .panel-group .panel-title a,
.comment-box-panels .panel-group .panel-body,
.comment-box-panels .panel-group .panel-group .panel-heading + .panel-collapse > .panel-body {
	border-radius: 2px;
	border: 0;
}
.comment-box-panels .panel-group .panel-heading {
	padding: 0;
}
.comment-box-panels .panel {
	position: relative;
}
.comment-box-panels .panel-group .panel-heading a {
	display: block;
	background: #ffffff;
	/*color: #ffffff;*/
	padding: 10px;
	text-decoration: none;
	position: relative;
}
.comment-box-panels .panel-group .panel-heading a.collapsed {
	background: #ffffff;
	color: inherit;
}
.comment-box-panels .panel-group .panel-heading .panel-title > a:before {
	font-family: "FontAwesome";
	content: "\f0d7";
	position: absolute;
	left: -15px;
	top:5px;
	font-size:20px;
}
.comment-box-panels .panel-group .panel-heading .panel-title > a.collapsed:before {
	font-family: "FontAwesome";
	content: "\f0da";
}
.comment-box-panels .panel-group .panel-collapse {
	/*margin-top: 5px !important;*/
	margin-top: 0px !important;
}
.comment-box-panels .panel-group .panel-body {
	background: #eee;
	padding: 0px;
}
.comment-box-panels .panel-group .panel {
	background-color: transparent;
	box-shadow:none;
	margin-top: 5px;
}
.panel-group .panel-body p:last-child,
.panel-group .panel-body ul:last-child,
.panel-group .panel-body ol:last-child {
	margin-bottom: 0;
}
.comment-box-panels .panel-group {
	margin-bottom: 0px;
}
.comment-box-panels .panel-group .panel-body {
	border-top: 1px solid #ddd !important;
}
.comment-box-panels .panel-group .panel-group .panel {
	margin-left: 15px;
}
.history-box {
	padding: 0px 30px 15px;
}
.history-label {
	display: block;background: #ffffff;color: #ffffff;padding: 10px;text-decoration: none;position: relative;margin-bottom: 5px;
}
.history-changes {
	position: absolute;right: 10px;top: 10px;color: #333;font-size: 12px;
}
.history-changes > p {
	font-size: 12px;
}
.undo-icon {
	position: absolute;right: -20px;color: #800000;font-size: 20px;top: 15px;
}
/*Profile edit page*/
.form-horizantal-label .form-group .control-label  {
	font-weight: normal;
	text-align: left;
}
.profile-img {
	margin: 20px auto;
}
.profile-img > img {
	display: block;
	max-width: 100%;
	width: 128px;
	height: 128px;
	border-radius: 50%;
	padding: 5px;
	border: 2px solid #f5f5f5;
	-webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,.07);
	-moz-box-shadow: 0 0 4px 0 rgba(0,0,0,.07);
	box-shadow: 0 0 4px 0 rgba(0,0,0,.07);
	margin: 0 auto;
}
.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
}
.upload-btn-wrapper > .btn.btn-upload {
	color: #0277bd;
	background-color: transparent;
	border: 1px solid #0277bd;
	padding: 6px 12px;
	border-radius: 4px;
	font-size: 14px;
}
.upload-btn-wrapper input[type=file] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}
input {
	caret-color: red;
}
/*End Profile edit page*/
/*Start Add user comments*/
.user-mgmt-height {
	height: 350px;overflow-y: scroll;
}
.adduser-label {
	position: relative;
	display: block;
	background: #ffffff;
	padding: 10px;
	text-decoration: none;
	margin-bottom: 5px;
}
.adduser-content .adduser-icon {
	float: left;
	width: 50px;
}
.adduser-content .adduser-text {
	padding-left: 50px;
	padding-top: 0px;
}
.adduser-content .adduser-text p {
	margin-bottom: 0px;
	line-height: 1.3;
	color: #333333 !important
}
.adduser-text > p > a.uchanges {
	text-decoration: underline;
	color: blue;
}
.sub-date-time {
 color: #808080 !important;
 font-weight: normal;
 font-size: 12px;
}
.remove-user {
	position: absolute;
	top: 15px;
	right: 5px;
	font-size: 18px;
	color: red;
}
.adduser-label.active, .adduser-label.active:focus, .adduser-label.active:hover, .adduser-label:hover {
	background-color: #bcf4bc;
	border-color: #90ee90;
}
.add-user-comments .form-group {
	margin-bottom: 0px;
	display: inline-block;
}
/*End Add user comments*/
.sub-vertical-tab {
	height: 250px;overflow-y: scroll;padding: 10px;border: 1px solid #333;
}
.role-mgmt-panels .panel-title a {
	padding: 8px 30px 8px 30px;
	display: inline-block;
	width: 100%;
	background-color: #ffffff;
	color: #333333;
	position: relative;
	text-decoration: none;
	font-weight: 700;
	font-size: 14px;
}
.role-mgmt-panels .panel-heading {
	padding: 0px;
}
.role-mgmt-panels .panel-heading a.collapsed::before {
	content: "\f0da";
	color: #0099ff;
}
.role-mgmt-panels .panel-heading a::before {
	font-family: "FontAwesome";
	content: "\f0d7";
	position: absolute;
	left: 10px;
	font-size: 16px;
	font-weight: 500;
	top: 50%;
	line-height: 1;
	margin-top: -10px;
	color: #0099ff;
}
.role-mgmt-panels .panel-heading a.lock::after {
	font-family: "FontAwesome";
	content: "\f023";
	position: absolute;
	right: 10px;
	font-size: 16px;
	font-weight: 500;
	top: 50%;
	line-height: 1;
	margin-top: -10px;
	color: #333333;
}
/*Start Vertical accordion collapse list*/
.u-vmenu ul {
	padding: 0px;
	margin: 0px;
}
.u-vmenu ul li {
	width: 100%;
	list-style: none;
	margin-bottom: 5px;
}
.u-vmenu ul ul{
	display: none;
	padding-left: 30px;
	margin-top: 5px;
}
.u-vmenu ul li .anchor-replace {
	display: block;
	padding: 10px;
	background-color: #f8f8f8 !important;
	font-size: 16px;
	color: #333;
	text-decoration: none;
	outline: none;
	cursor: pointer;
}
.u-vmenu ul li .anchor-replace[data-option='on']:before {
	content: "\f0d7";
	font-family: FontAwesome;
	display: inline-block;
	font-size: 16px;
	color: #0099ff;
	width: 20px; 
}
.u-vmenu ul li .anchor-replace[data-option='off']:before {
	content: "\f0da";
	font-family: FontAwesome;
	display: inline-block;
	font-size: 16px;
	color: #0099ff;
	width: 20px; 
} 
.u-vmenu .badge {
	background-color: #337ab7;
}
/*End Vertical accordion collapse list*/

.tr-bg-green {
	background-color: #90ee90 !important;
}
.modal-lg {
	min-width: 80%;
}
.cdt-modal-lg {
	min-width: 60%;
}
.nav-pad-zero {
	padding-left: 0px;
	padding-right: 0px;
}
.design-template-tabs {
	margin-top: 10px;
	padding: 10px;
	border: 1px solid #333;
}
h3.cdt-sub-title {
	color: #0B4BA3;
	padding: 5px 0px 0px;
	font-size: 18px;
	margin-top: 0px;
}
.bg-white {
	background-color: #ffffff !important;
}
.bg-fgray {
	background-color: #f8f8f8;
} 
.bg-egray {
	background-color: #eeeeee;
}
.pad-all-0 {
	padding: 0px;
}
.pad-all-8 {
	padding: 8px;
}
.pad-all-10 {
	padding: 10px;
}
.pad-all-15 {
	padding: 15px;
}
.cls-gray {
	color: #808080 !important;
}
.cls-blue {
	color: #0b4ba3 !important;
}
.cls-green {
	color: #008000 !important;
}
.cls-red {
	color: #ff0000 !important;
}
.cls-mn-blue {
	color: #0099ff !important;
}
.cls-meroon {
	color: #800000 !important;
}
.fsize-14 {
	font-size: 12px !important;
}
.mar-top-zero {
	margin-top: 0px;
}
.mar-btm-zero {
	margin-bottom: 0px;
}
.mar-top5 {
	margin-top: 5px;
}
.mar-btm5 {
	margin-bottom: 5px;
}
.mar-top10 {
	margin-top: 10px;
}
.mar-btm10 {
	margin-bottom: 10px;
}
.mar-tb-5 {
	margin-top: 5px;
	margin-bottom: 5px;
}
.marTop30 {
	margin-top: 30px;
}
.mBtm20 {
	margin-bottom: 20px;
}
.small-text {
	color: #666666 !important;
	font-weight: normal;
	font-size: 14px;
}
.btn {
	box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
	border-radius: 2px !important;
	text-transform: uppercase;
	font-size: 16px !important;

}
.btn:active, .btn:focus, .btn:hover {
	box-shadow: 0 5px 11px 0 rgba(0,0,0,.18),0 4px 15px 0 rgba(0,0,0,.15);
	outline: 0;
}
.cls-primary {
	color: #0277bd !important;
}
.cls-success {
	color: #00c851 !important;
}
.cls-danger {
	color: #ff3547 !important;
}
.cls-warning {
	color: #fb3 !important;
}
.cls-cdt {
	color: #0099ff !important;
}
.cls-white {
	color: #ffffff !important;
}
.cls-default {
	color: #333333; 
}
.btn-primary {
	background-color: #0277bd !important;
	color: #ffffff !important;
	border-color: #0277bd !important;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:active:focus {
	background-color: #0277bd  !important;
	color: #ffffff !important;
	border-color: #0277bd;
}
.btn-success {
	background-color: #00c851 !important;
	color: #ffffff !important;
	border-color: #00c851 ;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success:active:focus {
	background-color: #1e7e34 !important;
	color: #ffffff !important;
	border-color: #1e7e34 !important;
}
.btn-danger {
	background-color: #ff3547 !important;
	color: #ffffff !important;
	border-color: #ff3547 !important;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger:active:focus {
	background-color: #ff4f5e !important;
	color: #ffffff !important;
	border-color: #ff4f5e !important;
}
.btn-warning {
	background-color: #ff6f00 !important;
	color: #ffffff !important;
	border-color: #ff6f00 !important;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning:active:focus {
	background-color: #ff6f00;
	color: #ffffff;
	border-color: #ff6f00;
}
.btn-info {
	background-color: #33b5e5;
	color: #ffffff;
	border-color: #33b5e5;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info:active:focus {
	background-color: #4abde8;
	color: #ffffff;
	border-color: #4abde8;
}
.btn-default {
	background-color: #0099ff;
	color: #ffffff;
	border-color: #0099ff;


}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default:active:focus  {
	background-color: #0099ff;
	color: #ffffff;
	border-color: #0099ff;
}
.btn-bold  {
	font-size: 16px !important;
	font-weight: bold !important;
}
.button-link {
	background-color: transparent;
	border: 1px solid transparent;
	cursor: pointer;
	padding-left: 0px;
}
.button-link:hover, .button-link:focus {
	text-decoration: underline;
}
.form-control:focus {
	border-color: #0277bd;
}
.no-border-td > tbody > tr > td {
	border-top: none;
}
.center {
	text-align: center !important;
}


@media screen and (max-height: 450px) {
	.sidenav {padding-top: 15px;}
	.sidenav a {font-size: 18px;}
}
@media only screen and (min-width: 320px) and (max-width: 550px) {
	.fgroup-fix-btm {
		width: 85%;
	}
	.fix-btm-div {
		width: 85%;
	}
	.nc-fgroup {
		min-width: 250px;
		max-width: 100%;
	}
}
@media only screen and (min-width: 320px) and (max-width: 480px) {

}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media (max-width: 1024px) {
	
}


.dropdown-menu > li > a{
	padding:0px !important;
	
}
.dropdown-menu > li > a:hover,.dropdown-menu > li > a:focus {
   background-color: #e5f6fd !important;
}

 li.nc_ul_list > .dropdown-toggle{
	background-color: transparent !important;
    cursor: pointer !important;
    clear: both !important;
    border: 0px !important;
    padding: 0px !important;


}
 #dropdown-custom-menu{
	background-color: #f8f8f8 !important;
    cursor: pointer !important;
    clear: both !important;
    border: 0px !important;
    padding: 0px !important;
    box-shadow: none !important;
    color: #fff !important;
}
#commands{
	left: 38px !important;
}
.nodeMaker{
	margin:0px !important;
}



