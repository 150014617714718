/*** Common ***/

.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.hidden {
	display: none !important;
}

/*** Editor page ***/

#editorPage {
	display: flex;
	flex-direction: column;
	height: 97vh;
}

/*** Editor ***/

#editor {
	caret-color: rgb(255, 53, 71);
	color: rgb(51, 51, 51);
	flex-grow : 1;
	font-size: 14px;
	overflow-y: scroll;
	padding: 4px;
}

#editor [contenteditable="true"] {
	display: inline-block;
}

#editor [readonly="true"] {
	background-color: rgb(242, 242, 242);
}

#editor [readonly="false"] {
	background-color: white;
}

/*** Node structure ***/

#editor .node {
	display: flex;
	flex-direction: column;
	/*font-size: 0.875em;*/
	margin-top: 0.2em;
}

#editor .node .node-base {
	display: flex;
	flex-direction: row;
}

#editor .node .node-wrapper {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

#editor .node .node-wrapper .node-header {
	display: flex;
	flex-direction: row;
}

#editor .node .node-wrapper .node-body {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

#editor .node .node-content {
	display: flex;
}

#editor .node .node-content .node-data {
	color: rgb(51, 51, 51);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

#editor .node .nodes {
}

/*** Node controls area with menu and folder/drag area ***/

#editor .node .node-control {
	background-color: rgb(251, 251, 251);
	border-bottom-left-radius: 0.5em;
	border-left: 1px dotted rgb(0, 153, 255);
	cursor: pointer;
	display: flex;
	flex-direction: column;
	max-width: 0.8em;
	min-width: 0.8em;
}

#editor .node .node-control.empty {
	cursor: default;
}

#editor .node .node-control .node-menu {
	color: rgb(0, 153, 255);
	cursor: pointer;
	font-size: 0.8em;
	margin-top: 0.25em;
}

/*** Node info line ***/

#editor .node .node-info{
	background-color: rgb(251, 251, 251);
	border-left: 1px dotted rgb(0, 153, 255);
	border-right: 1px dotted rgb(0, 153, 255);
	border-top-left-radius: 0.5em;
	border-top-right-radius: 0.5em;
	color: rgb(128, 128, 128);
	cursor: default;
	display: flex;
	/*font-size: 0.625em;*/
}

#editor .node .node-info .node-folder {
  color: rgb(0, 153, 255);
  cursor: pointer;
}

#editor .node .node-info .node-folder.empty {
  color: rgb(128, 128, 128);
  cursor: default;
}

#editor .node .node-info .node-folder .fold-nodes {
  font-size: 1.333em;
  max-height: 1.333em;
}

#editor .node .node-info .node-id a{
	color: rgb(0, 153, 255);
	cursor: pointer;
}

/*** Node involved ***/

#editor .node-info .involveds {
	display: flex;
	flex-wrap: wrap;
	margin-left: 0.5em;
}

#editor .node-info .involveds .involved {
	border-left: 1px solid rgb(128, 128, 128);
	border-radius: 0.25em;
	border-right: 1px solid rgb(128, 128, 128);
	display: flex;
	margin-right: 0.5em;
	padding-left: 0.2em;
	padding-right: 0.2em;
	white-space: nowrap;
}

#editor .node-info .involveds .involved .involved-role {
	color: rgb(0, 153, 255);
	cursor: pointer;
}

#editor .node-info .involveds .involved .involved-users{
	display: flex;
	flex-wrap: wrap;
}

#editor .node-info .involveds .involved .involved-user{
	cursor: default;
	margin-left: 0.2em;
}

#editor .node-info .involveds .involved .involved-add {
	color: rgb(0, 153, 255);
	cursor: pointer;
}

/*** Node info line buttons related to editing/reading ***/

#editor .node .node-info .mark-as-read {
	align-items: center;
	align-self: flex-start;
	color: rgb(0, 153, 255);
	cursor: pointer;
	display: flex;
	margin-left: auto;
	white-space: nowrap;
}

#editor .node .node-info .mark-as-read .material-icons {
	font-size: 1.333em;
	margin-left: 0.2em;
}

#editor .node .node-info .edit-confirm{
	align-items: center;
	align-self: flex-start;
	color: rgb(46, 139, 87);
	cursor: pointer;
	display: flex;
	white-space: nowrap;
}

#editor .node .node-info .edit-confirm .material-icons{
	font-size: 1.333em;
	margin-left: 0.2em;
}

#editor .node .node-info .edit-cancel {
	align-items: center;
	align-self: flex-start;
	color: rgb(255, 53, 71);
	cursor: pointer;
	display: flex;
	margin-left: auto;
	margin-right: 0.5em;
	white-space: nowrap;
}

#editor .node .node-info .edit-cancel .material-icons {
	font-size: 1.333em;
	margin-right: 0.2em;
}

#editor .node .node-info .edit-approve{
	align-items: center;
	align-self: flex-start;
	color: rgb(46, 139, 87);
	cursor: pointer;
	display: flex;
	white-space: nowrap;
}

#editor .node .node-info .edit-approve .material-icons{
	font-size: 1.333em;
	margin-left: 0.2em;
}

#editor .node .node-info .edit-decline {
	align-items: center;
	align-self: flex-start;
	color: rgb(255, 53, 71);
	cursor: pointer;
	display: flex;
	margin-left: auto;
	margin-right: 0.5em;
	white-space: nowrap;
}

#editor .node .node-info .edit-decline .material-icons {
	font-size: 1.333em;
	margin-right: 0.2em;
}

/*** Node content ***/

#editor .node .node-number {
	color: rgb(51, 51, 51);
	cursor: default;
	display: inline-block;
	font-weight: 700;
	margin-right: 0.2em;
}

#editor .node .node-name {
	color: rgb(51, 51, 51);
	flex-grow: 1;
	font-weight: 700;
	margin-right: 1px;
	min-height: 1.333em;
}

#editor .node .node-name:focus {
	background-color: rgb(230, 245, 255);
	font-size: 1.333em;
}

#editor .node .node-name[placeholder]:empty:before {
	color: rgb(204, 204, 204);
	content: attr(placeholder);
	margin-left: 0.2em;
}

#editor .node .node-description {
	border: 1px dotted rgb(224, 224, 224);
	color: rgb(71, 71, 71);
	flex-grow: 1;
	/*font-size: 0.875em;*/
	margin-right: 1px;
	margin-bottom: 20px;
	margin-top: 0.2em;
	min-height: 1.25em;
}

#editor .node .node-description:focus {
	background-color: rgb(230, 245, 255);
	border: 1px dotted rgba(0, 0, 0, 0);
	font-size: 1.333em;
}

#editor .node .node-description[placeholder]:empty:before {
	color: rgb(204, 204, 204);
	content: attr(placeholder);
	margin-left: 0.2em;
}

/*** Node right area to show/hide description ***/

#editor .node .node-state {
	background-color: rgb(251, 251, 251);
	border-bottom-right-radius: 0.5em;
	border-right: 1px dotted rgb(0, 153, 255);
	color: rgb(0, 153, 255);
	cursor: pointer;
	display: flex;
	flex-direction: column;
}

#editor .node .node-state.empty {
	color: rgb(128, 128, 128);
}

#editor .node .node-state .fold-description{
	margin-bottom: 0.25em;
	margin-top: auto;
	/*font-size: 0.8em;*/
	max-width: 1em;
}

/*** User ***/

#editor .node.user .node-name.user-nick-name {
	color: rgb(128, 128, 128);
}

#editor .node.user .node-name.user-nick-name:focus {
	background-color: rgb(230, 245, 255);
	font-size: 1.333em;
}

#editor .node.user .node-name.user-nick-name[readonly="true"] {
	background-color: rgb(255, 255, 255);
}

#editor .node.user .node-name.user-nick-name:focus {
	background-color: rgb(230, 245, 255);
}