/*Help styles*/
#helpPage #logo{
	color: rgb(51,51,51)!important;
	display: flex;
	font-size: 24px !important;
	font-weight: 700;
	align-items: center;
	text-decoration: none;
	margin-right: auto;
}

#helpPage #logo .logo-mn{
	color: rgb(0, 153, 255) !important;
	font-size: 28px;
	font-weight: 700;
	margin-top: -3px;
}


#helpPage .landing-header{
	border-bottom: 1px solid #0099ff !important;
	background-color: #f8f8f8;
	display: flex;
	flex-direction: row;
	padding: 8px 15px;
}

#helpPage .landing-header .lr-links{
	align-items: center;
	display: flex;
	margin-left: auto !important;
	padding-bottom: 5px !important;
	padding-top: 5px !important;
}


#pricing{
	color: rgb(0, 153, 255);
	font-size: 16px;
}

#helpName{
	font-size: 28px;
	font-weight: 700;
	padding: 20px;
	text-align: center;
}

#helpPage .help-chapter{
	border: 1px solid #999;
	box-shadow: 10px 10px 5px rgb(139, 139, 139);
	margin-bottom: 30px;
}

#helpPage .help-chapter-name{
	background-color: rgb(0, 153, 255);
	color: rgb(255, 255, 255);
	font-size: 24px;
	font-weight: 700;
	padding: 15px;
}

#helpPage  .help-chapter-text{
	font-size: 16px;
	padding: 15px;
	text-align: start;
	
}

#manualTOC .manual-toc-title{
	color: rgb(51, 51, 51);
	font-size: 16px;
	font-weight: 700;
}

#manualTOC .manual-toc-link {
	color: rgb(0, 153, 255);
	text-decoration: underline;
}

#helpPage .manual-article .manual-article-name{
	background-color: rgb(255, 255, 255);
	border-bottom-color: rgb(0, 153, 255); 
	border-bottom-style: solid;
	border-bottom-width: 1px;
	color: rgb(51,51,51);
	font-size: 24px;
	font-weight: 400;
	padding: 15px;
	text-align: start;
}

#helpPage .manual-article .manual-article-text{
	background-color: rgba(0, 0, 0, 0);
	color: rgb(51, 51, 51);
	font-size: 16px;
	font-weight: 400;
	text-align: start;
}

#helpPage .manual-article-text{
	border-bottom: 1px solid rgb(153, 153, 153);
	font-size: 16px;
	padding: 15px;
    text-align: left;
}

#helpPage .manual-article{
	border: 1px solid rgb(153, 153, 153);
    margin-top: 30px;
}

#helpPage .manual-article .center-img{
	text-align: center;
	margin: 30px;
}

#helpPage .help-chapter .help-chapter-text .contacts-company{
	font-weight: 700;
	padding: 0px;
}

#helpPage .help-chapter .help-chapter-text .contacts-owner{
	font-weight: 700;
	margin-top: 10px;
}

#helpPage .help-chapter .help-chapter-text .contacts-email{
	color: rgb(0, 153, 255);
}

#helpPage .help-chapter .help-chapter-text .contacts-city{
	margin-bottom: 30px;
}
.glyphicon-phone-alt,.glyphicon-print,.glyphicon-envelope{
	margin-left: -5px;
	margin-right: 20px;
	top: 2px !important;

}
