#usersPopup{
	background-color: rgb(255, 255, 255);
	border-color: rgb(221, 221, 221);
	box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.176); 
}

#usersPopup .users-at {
	color: rgb(0, 153, 255);
	font-size: 24px;
	font-weight: 700;
	padding-bottom: 0px;
	padding-left: 6px;
	padding-right: 6px;
	padding-top: 0px;
}

#usersPopup .users-popup-header{
	/*display: inline-flex;*/
	/*margin-bottom: 225px;*/
    vertical-align: middle;
}

#usersPopup .users-filter {
/*	border: 1px dotted blue; */
	margin-left: 10px;
}

#usersRole {
/*	border: 1px dotted red;
	order: 1;

	flex-grow: 1;
	align-self: flex-end;*/
}

#usersFilter{
/*	border: 1px solid #ccc;
	color: rgb(0, 153, 255);*/
	font-size: 14px;
	font-weight: 400;
	width: 350px;
}

#usersPopupClose{
	background-color: rgb(255, 255, 255) !important;
	display: inline-block;
	margin-left: 12px;
	float: right;
}

#usersPopup .popover-content{
	padding-right: 0px;
}

#usersPopupClose span{
	color: rgb(255, 53, 71) !important;	
	font-size: 16px;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0px;
}

#usersRole{
	color: rgb(51, 51, 51);
	font-size: 14px;
	font-weight: 400;
	padding-right: 0px;
	/*flex-grow: 1;*/
	width: 150px;
	display: inline-block;
}

#usersFilter .user{
/*	background-color: transparent;
	border-bottom: 1px solid #dddddd;
*/	
	display: flex;
	align-items: center;
}

#usersFilter .css-1rhbuit-multiValue .user .user-email {
	display: none;
}

#usersFilter .user .user-avatar{
	display: inline-flex;
	height: 32px;
	width: 32px;
}

#usersFilter .css-1rhbuit-multiValue .user .user-avatar {
	height: 16px;
	width: 16px;
}

#usersFilter .user .user-name{
	color: rgb(51, 51, 51);
	font-size: 14px;
	font-weight: 700;
	margin-left: 6px;
}

#usersFilter .css-1rhbuit-multiValue .user .user-name {
	font-weight: 400;
}

#usersFilter .user .user-nick{
	color: rgb(51, 51, 51);
	font-size: 14px;
	font-weight: 400;
}

#usersFilter .css-1rhbuit-multiValue .user .user-nick {
	display: none;
}

#usersFilter .user .user-email{
	color: rgb(0, 153, 255);
	font-size: 12px;
	font-weight: 400;
	margin-left: 6px;
	text-decoration-line: underline;
}


/* !!! SIVAJI DO NOT SYNC CSS BELOW !!! */

.test {
	border: 1px solid rgb(221, 221, 221);
	color: blue;
	display: block;
	padding: 5px;
	width: 290px;
}


p {
  margin: 0 0 1.5em;
}

body::-webkit-scrollbar {
	width: 1em;

}
 
body::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}