.c_line-header {
	background-color: #f8f8f8;
	border-bottom: 1px solid #0099ff;
	/*padding: 8px 15px;*/
	position: -webkit-sticky;
	position: sticky;
	top:0px;
}
.nc_ul_menu {
	padding: 0px;
	margin: 0px;
}
.node-eCommand{
	padding: 15px;
}
.node-eCommandList{
	padding: 5px;
	background-color: #ffffff;
}

.nc_ul_menu li.nc_ul_list {
	font-size: 24px !important;
	list-style-type: none;
	display: inline-block;
	padding-right: 8px;
	font-weight: bold;
}
.nc_brackets {
	font-size: 24px;
	font-weight: bold;
	line-height: 24px;
	position: relative;
	top: 4px;
}
.nc_dots {
	font-size: 28px;
	line-height: 28px;
}
.ip-group input.form-control {
	color: #0099ff;
}
.nc_alerts {
	position: relative;
}
.nc_alerts span.badge {
	position: absolute;
	top: -8px;
	right: 0px;
	background-color:#ff3547;
	padding: 4px;
	border: 1px solid #333;
	border-radius: 2px;
}
.anchor-link {
	background-color: transparent;
	cursor: pointer;
	clear: both;
	border: 0px;
	padding: 0px;
}
.nc_ul_list .settings-list {
	right: 0px;
	left: auto;
	padding: 0px;
	margin: 0px;
	margin-top: 11px;
	border-left:  1px solid #dddddd;
	border-right: 1px solid #dddddd;
	border-top: 1px solid #dddddd;
}
.nc_ul_list .settings-list li {
	display: block;
}
.nc_ul_list .settings-list li .anchor-link {
	display: block;
	border: 0px;
	border: 1px solid transparent;
	border-bottom: 1px solid #dddddd;
	padding: 5px 8px;
	min-width: 320px;
	text-align: left !important;
	background-color: transparent;
	cursor: pointer;
	clear: both;
}
.nc_ul_list .settings-list li .anchor-link img {
	float: left;
	width: 32px;
	height: 32px;
	margin-right: 10px;
	margin-top: 5px;
}
.nc_ul_list .settings-list li .anchor-link:hover {
	background-color: #e5f6fd !important;
}
.nc_ul_list .settings-list li .redImg:hover {
	background-color: #ffeaec !important;
}
.nc_ul_list .settings-list li .anchor-link:hover img{
	-webkit-filter: invert(43%) sepia(27%) saturate(5145%) hue-rotate(183deg) brightness(102%) contrast(111%);
	filter: invert(43%) sepia(27%) saturate(5145%) hue-rotate(183deg) brightness(102%) contrast(111%);
}
.activeView {
	-webkit-filter: invert(43%) sepia(27%) saturate(5145%) hue-rotate(183deg) brightness(102%) contrast(111%);
	filter: invert(43%) sepia(27%) saturate(5145%) hue-rotate(183deg) brightness(102%) contrast(111%);

	
}
.inactiveView {
	filter: brightness(0.5) invert(.5) sepia(0.5) hue-rotate(50deg) saturate(50%);
	-webkit-filter: brightness(0.5) invert(.5) sepia(0.5) hue-rotate(50deg) saturate(50%);
}
.inactiveView + p.toread-text {
	color: #8b8b8b !important;
}
.redImg:hover img {
	filter: none !important;
}
.nc_ul_list .settings-list li .anchor-link .node-text {
	font-size: 16px;
	color: #333333;
	font-weight: bold;
	margin-bottom: 0px;
	/*margin-left: 42px;*/
}
.nc_ul_list .settings-list li .anchor-link .n-sub-txt {
	font-size: 14px;
	color: #8b8b8b;
	font-weight: normal;
}
/*Start Node commands*/
.nc-fgroup {
	padding: 0px;
	margin-bottom: 0px;
	display: inline-block;
	min-width: 450px;
	max-width: 500px;
}
.nc-fgroup .form-group {
	margin-bottom: 0px;
}
.nc-fgroup .ip-group {
	position: relative;
}
.nc-fgroup .ip-input {
	position: relative;
	width: 100%;
}
.nc-fgroup .iga-btn {
	position: absolute;
	top: 0px;
	right: 0px;
	background-color: transparent;
	cursor: pointer;
	padding: 7px 12px;
	border-left: 1px solid #ddd;
	border-radius: 3px;
	font-size: 14px;
}
.ncgroupInline {
	display: inline-block;
}/*
.ip-group #commandLineHide {
	opacity: 0;
}*/
.nc_commands_list {
	display: none;
	max-width: 450px;
	max-height: 95%;
	overflow-y: scroll;
	border: 1px solid #dddddd;
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
	box-shadow: 0 6px 12px rgba(0,0,0,.175);
	position: fixed;
	top: 45px;
	z-index: 99;
	    left: auto !important;
}
.node-commands-group, .to-read-group  {
	margin-bottom: 18px;
}
.node-commands-group li.command-item  {
	position: relative;
	padding: 4px 8px;
	display: block;
	margin-bottom: -1px;
	background-color: #fff;
	border: 1px solid #ddd;
	cursor: pointer;
}
li.command-item:hover, .node-commands-hover li.command-item:focus {
	background-color: #e5f6fd;
}
.node-commands-hover li.command-item:hover .node-img, .node-commands-hover li.command-item:focus .node-img {
	-webkit-filter: invert(43%) sepia(27%) saturate(5145%) hue-rotate(183deg) brightness(102%) contrast(111%);
	filter: invert(43%) sepia(27%) saturate(5145%) hue-rotate(183deg) brightness(102%) contrast(111%);
}
.node-commands-hover li.command-item:last-child:hover, .node-commands-hover li.command-item:last-child:focus {
	background-color: #ffeaec !important;
}
.node-commands-hover li.command-item:last-child:hover .node-img, .node-commands-hover > li.command-item:last-child:focus .node-img {
	filter: none ;
}
.node-commands-group li.command-item span.badge {
	float: right;
	background-color: transparent;
	color: #0099ff;
	font-weight: normal;
	padding: 0px;
	padding-top: 4px;
	border-radius: 0px;
}
.node-commands-group li.command-item .dots-list {
	position: absolute;
	right: 8px;
	bottom: 0px !important;
	top: auto;
	font-size: 24px;
	color: #999999;
}
.nc-item > .node-img {
	float: left;
	width: 32px; 
	height: 32px;
	margin-right: 10px;
	margin-top: 5px;
}
.nc-item p.node-text {
	font-size: 16px;
	color: #333333;
	font-weight: bold;
	margin-bottom: 0px;
	margin-left: 42px;
	min-height: 45px;
}
.nc-item p.node-text .n-sub-cut {
	font-size: 16px;
	color: #8b8b8b;
}
.nc-item p.node-text .n-sub-txt {
	font-size: 14px;
	color: #8b8b8b;
	font-weight: normal;
}
.nc-item p.toread-text {
	font-size: 14px;
	color: #333333;
	font-weight: bold;
	margin-bottom: 0px;
	margin-left: 42px;
	min-height: 45px;
	padding-top: 10px;
}
.nc-item p.toread-text .flight {
	font-weight: normal;
}
.fgroup-fix-btm {
	position: fixed;
	bottom: 0px;
	width: 100%;
}
.fix-btm-div {
	position: fixed;
	width: 100%;
	bottom: 50px;
	display: none;
	max-width: 500px;
	max-height: 418px;
	overflow-y: scroll;
	border: 1px solid #dddddd;
	box-shadow: 0.0625rem 0.125rem 0.1875rem rgba(0,0,0,0.2);
}
.to-read-group {
	width: 100%;
	min-width: 436px;
}
/*Settings modal styles*/
.pad-all-10 {
	padding: 10px;
}
.pad-all-15 {
	padding: 15px;
}
.mar-top-zero {
	margin-top: 0px;
}
.mar-btm-zero {
	margin-bottom: 0px;
}
.mar-btm10 {
	margin-bottom: 10px;
}
h3.cdt-sub-title {
	color: #0B4BA3;
	padding: 5px 0px 0px;
	font-size: 18px;
	margin-top: 0px;
}
.bg-white {
	background-color: #ffffff !important;
}
.form-horizantal-label .form-group .control-label  {
	font-weight: normal;
	text-align: left;
}
.profile-img {
	margin: 5px auto 10px;
}
.profile-img > img {
	display: block;
	max-width: 100%;
	width: 128px;
	height: 128px;
	border-radius: 50%;
	padding: 5px;
	border: 2px solid #f5f5f5;
	-webkit-box-shadow: 0 0 4px 0 rgba(0,0,0,.07);
	-moz-box-shadow: 0 0 4px 0 rgba(0,0,0,.07);
	box-shadow: 0 0 4px 0 rgba(0,0,0,.07);
	margin: 0 auto;
}
.upload-btn-wrapper {
	position: relative;
	overflow: hidden;
	display: inline-block;
}
.upload-btn-wrapper > .btn.btn-upload {
	color: #ffffff;
	background-color: transparent;
	border: 1px solid #0277bd;
	padding: 6px 12px;
	border-radius: 4px;
	font-size: 14px;
}
.upload-btn-wrapper input[type=file] {
	font-size: 100px;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
}
.modal-header .close {
	opacity: 1;
	font-size: 32px;
}
.no-border-td > tbody > tr > td {
	border-top: none;
}
.center {
	text-align: center !important;
}
.switchToggle input[type=checkbox]{height: 0; width: 0; visibility: hidden; position: absolute; }
.switchToggle label {cursor: pointer; text-indent: -9999px; width: 70px; max-width: 70px; height: 30px; background: #d1d1d1; display: block; border-radius: 6px; position: relative; }
.switchToggle label:after {content: ''; position: absolute; top: 2px; left: 2px; width: 26px; height: 26px; background: #fff; border-radius: 90px; transition: 0.3s; }
.switchToggle input:checked + label, .switchToggle input:checked + input + label  {background: #3e98d3; }
.switchToggle input + label:before, .switchToggle input + input + label:before {content: 'OFF'; position: absolute; top: 5px; left: 35px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {content: 'ON'; position: absolute; top: 5px; left: 10px; width: 26px; height: 26px; border-radius: 90px; transition: 0.3s; text-indent: 0; color: #fff; }
.switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {left: calc(100% - 2px); transform: translateX(-100%); }
.switchToggle label:active:after {width: 60px; } 
.toggle-switchArea { margin: 10px 0 10px 0; }

.settings-mbody {
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}
.settings-mbody hr {
	border-top: 1px solid #dddddd !important;
}
@media only screen and (max-width: 480px) {
	.nc-fgroup {
    min-width: 240px;
  }
	.nc_commands_list {
		max-width: 350px;
		top: 45px;
		left: 12px;
	}
	.login-wrapper {
		padding-top: 15px !important;
	}
}
@media only screen and (min-width: 768px) and (max-width: 1366px) {
	.nc-fgroup {
		min-width: 360px;
	}
	.nc_commands_list {
		max-width: 375px;
	}
} 
