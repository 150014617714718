#cdt-wrapper{
float: left;
padding-left:60px;
}
.userList{
	padding-bottom: 10px;
	width: 400px;
	background-color: #ffffff;
	margin-left: 0px;
}
.shadow{
	box-shadow:0px 4px 9px -2px #838080;
}
.newUserlist{
	position: absolute;
}

.menuli{
	width: auto;
	padding: 10px;
	list-style: none;
	border-bottom: solid 1px #aaaaaa; 
}
.menuli:hover{
	width: auto;
	padding: 10px;
	background-color: #cccccc;
}
.menuUl{
	width: auto;
	left: -1px;
	background-color: #ffffff;
	display: block;
	box-shadow:0px 4px 9px -2px #838080;
}
.menulidiv{

	width: auto;
}
.sigCanvas{
	background-color: rgba(0,0,0,0);
	position:absolute;
	width: 100%;
	min-width: 1200px;
	min-height: 1200px;
	height:100vh;
	z-index: 2;
}
.sigCanvas1{
	background-color: rgba(0,0,0,0);
	width: 100%;
	height: inherit;
}
.nodeMaker{
	text-align: left;
	float: left;
	clear: left;
	
}
.editableContent{
	outline: none;
}
.nodesList{
	text-align: left;
}
.mainNodeL{
	border-left: 0px !important; 
	border-right: 0px !important;
	border-bottom: 1px solid red !important;
	border-top: 0px !important;
	font-size: 14px;
}
.mainNodeL:focus{
	outline: none;
}
.mainNodeR{
	border-left: 0px !important; 
	border-right: 0px !important;
	border-bottom: 1px solid red !important;
	border-top: 0px !important;
}
.mainNodeR:focus{
	outline: none;
}
.controls{
	/*color: #0099ff;*/
	width: 100%;
	text-align: center;
	color:#000099;
	box-shadow:0px 4px 9px -2px #838080 ;
}
.cmdBtn{
	background-color: transparent;
	border: 1px solid transparent;
	cursor: pointer;
}

.editorActionIcons .material-icons {
	font-size: 18px;
	color: rgb(51,51,51);
	padding-left: 5px;

}

.editorActionIcons .glyphicon {
	padding-left: 5px;
	font-size: 17px;
	color: rgb(51,51,51);
	top:-2px !important;	
}

.subNode{
	border-left: 0px !important; 
	border-right: 0px !important;
	border-bottom: 1px solid red !important;
	border-top: 0px !important;
}
.siblingNode{
	border-left: 0px !important; 
	border-right: 0px !important;
	border-bottom: 1px solid red !important;
	border-top: 0px !important;
}

.liTitle{
	font-size: 14px !important;
	margin: 0px;
	/*text-decoration: underline;*/
	outline: none;
	/*font-weight: bold;*/
}

